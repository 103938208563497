// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  elses = 5,
  pressed = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  play = 12,
  is = 13,
  add = 14,
  from = 15,
  remove = 16,
  to_list = 17,
  clear = 18,
  ins = 19,
  not_in = 20,
  Comment = 21,
  Program = 22,
  Command = 23,
  Assign = 24,
  Text = 25,
  ListAccess = 26,
  AssignList = 27,
  Ask = 29,
  String = 30,
  Clear = 31,
  Print = 32,
  Play = 33,
  Turtle = 34,
  Sleep = 38,
  Add = 39,
  Remove = 40,
  If = 41,
  Condition = 42,
  IfLessCommand = 46,
  ErrorInvalid = 47,
  Else = 48
