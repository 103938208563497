// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "0bQYQPOOOOQO'#D_'#D_QYQPOOO!TQPO'#DbO!fQPO'#D]OOQO'#Dh'#DhO!tQPO'#C{OOQO'#Di'#DiO#SQPO'#C|OOQO'#Dj'#DjO#_QPO'#C}OOQO'#Dk'#DkO#gQPO'#DPOOQO'#Dl'#DlO#oQPO'#DQOOQO'#Dm'#DmO#wQPO'#DROOQO'#DO'#DOOOQO'#Dn'#DnO$PQPO'#DSOOQO'#Do'#DoO$bQPO'#DTOOQO'#Dq'#DqO$jQPO'#DUOOQO'#Ds'#DsO$rQPO'#DVOOQO'#Dw'#DwO%oQPO'#D^OOQO'#Cs'#CsQ!OQPO'#D`Q%vQPOOOOQO-E7]-E7]OOQO'#Da'#DaO%}QPO,59`OOQO'#Db'#DbOOQO-E7`-E7`OOQO-E7f-E7fO&YQPO'#DgOOQO'#Dg'#DgO&nQPO,59hOOQO-E7g-E7gO'PQPO'#DbO'nQPO,59iOOQO-E7h-E7hOOQO,59i,59iO'|QPO,59kOOQO-E7i-E7iOOQO,59k,59kO([QPO,59lOOQO-E7j-E7jOOQO,59l,59lO(jQPO,59mOOQO-E7k-E7kOOQO,59m,59mO(xQPO,59nOOQO-E7l-E7lOOQO,59n,59nO)WQPO,59oOOQO-E7m-E7mO)`QPO,59pOOQO-E7o-E7oOOQO-E7q-E7qO)hQPO'#DXOOQO'#DW'#DWO`QPO,59qO`QPO,59xOOQO'#D['#D[OOQO-E7u-E7uOOQO,59x,59xO)sQPO,59zOOQO-E7^-E7^OOQO-E7_-E7_O*qQPO1G.zOOQO1G.z1G.zOOQO'#Df'#DfO+]QPO1G/POOQO'#Dc'#DcO+hQPO,59bOOQO-E7e-E7eOOQO'#Dp'#DpO+sQPO1G/ZOOQO'#Dr'#DrO+{QPO1G/[O,TQPO,59sOOQO'#Du'#DuO,cQPO,59tOOQO'#Dv'#DvO,kQPO,59uO`QPO1G/]O,sQPO1G/]OOQO1G/d1G/dOOQO'#Cx'#CxO$eQPO'#DeO-OQPO7+$iOOQO-E7d-E7dO-gQPO7+$kOOQO-E7a-E7aOOQO'#Dd'#DdO-xQPO1G.|OOQO1G.|1G.|OOQO-E7n-E7nOOQO7+$u7+$uOOQO-E7p-E7pOOQO7+$v7+$vOOQO'#Dt'#DtO.^QPO1G/_OOQO1G/_1G/_OOQO-E7s-E7sOOQO1G/`1G/`OOQO-E7t-E7tOOQO1G/a1G/aO/UQPO7+$wOOQO7+$w7+$wO/aQPO,5:POOQO-E7c-E7cOOQO-E7b-E7bOOQO-E7r-E7rOOQO<<Hc<<Hc",
  stateData: "/{~OeOS!nOS~OShOTjOVVOWZOX]OY_OZbO[XO^dO`fObTOiRO!oPO~O]pOi!UX!l!UX!o!UXT!UX~OirO!l!PX!o!PXT!PX~ObTO!loX!ooXToX~OVVOiuOnvO~O[XOiyO~OWZOi}O~OX]Oi!QO~OY_Oi!TO~OZbOi!WO!lvX!ovXTvX~O^dOirO~O`fOirO~OShOi!`O~OTjOVVOWZOX]OY_OZbO[XO^dO`fObTOiRO~O!oPO~P$zOShO~P$zOP!lO]pOiyO~OQ!nOi!ZXn!ZX!l!ZX!o!ZXT!ZX~OiuOnvO!lpa!opaTpa~OQ!nOi!UX!l!UX!o!UX!p!UX!q!UX!r!UX!s!UXT!UX~OirO!lqa!oqaTqa~OQ!nO!lsa!osaTsa~OQ!nO!lta!otaTta~OQ!nO!lua!ouaTua~OQ!nO!lva!ovaTva~Oa!qOirO~O_!sOirO~O]pOc!vOd!xO~O!oPOS!SaT!SaV!SaW!SaX!SaY!SaZ!Sa[!Sa^!Sa`!Sab!Sai!Sa!l!Sa~OirO!p!}O!q!}O!r!}O!s!}O!lhi!ohiThi~OP!lOiuOnvO~OQ!nOR#TOi#VO~Oa!qOi#XO~O_!sOi#ZO~OU#[O]pOi#^On#^O~Oc!vOi#`O~Od!xOi#bO~OTjO!lyi!oyi~O!p!}O!q!}O!r!}O!s!}O!lkq!okqTkq~OiuOnvO!lmq!omqTmq~OR#TOijinji!lji!ojiTji~OU#[OV{iW{iX{iY{iZ{i[{i^{i`{ib{ii{i!o{i~OTjO!lyq!oyq~OirO!l!Xa!o!Xa!p!Xa!q!Xa!r!Xa!s!XaT!Xa~O",
  goto: "*Y!lPPPPPPPPPPPPPPPPPPPPPPP!m!rP!}!r#g!rP!r!r!r!r#k#k#k!r!r!r#t#y#|#|#|$P!r$]$h$z%R%]%}&Y&`&f&l&v'S'`'l'x(U(b(n(z)Q)^)d)l)r)x*OVmOQnUlOQnX!dk!b!c!zWvWw!m#RQ|YQ!P[Q!S^Q!V`Q!YcR!kqT#O!j#P_aOQkn!b!c!zVlOQnR!biR!aiQ!fkQ!{!bQ!|!cR#c!zUlOQnQ#d!{R#i#cQQOWoQ!c!g!zQ!ckQ!gmR!z!bSnOQR!hnQqRS!iq!uR!u!`^SOQkn!b!c!z[sSz!Z!]!j#eQzYQ!ZeQ!]gQ!jqR#e#O[!ouy}!Q!T!WR#S!oQ#U!oR#g#UQ#P!jR#f#PQ!mqR#Q!mQwWS!pw#RR#R!m^UOQkn!b!c!zRtU^WOQkn!b!c!zRxW^YOQkn!b!c!zR{Y^[OQkn!b!c!zR!O[^^OQkn!b!c!zR!R^^`OQkn!b!c!zR!U`^cOQkn!b!c!zR!Xc^eOQkn!b!c!zR![eQ!r!ZR#W!r^gOQkn!b!c!zR!^gQ!t!]R#Y!tUiOQnR!_iQ#]!uR#h#]Q!w!`R#_!wQ!y!`R#a!yYkOQn!{#cR!ek",
  nodeNames: "⚠ ask at random if else pressed print forward turn color sleep play is add from remove to_list clear in not_in Comment Program Command Assign Text ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand ErrorInvalid Else",
  maxTerm: 81,
  nodeProps: [
    ["group", 34,"turtle"]
  ],
  skippedNodes: [0,21],
  repeatNodeCount: 25,
  tokenData: ")V~R}YZ$Opq$Tqr$Yrs&ust'jtu$Yuv$Yvw$Ywx(Rxy$Yyz$Yz{$Y{|$Y|}(q}!O$Y!O!P$Y!P!Q$Y!Q![$Y![!]$Y!]!^$Y!^!_$Y!_!`$Y!`!a$Y!a!b$Y!b!c$Y!c!}$Y!}#O$Y#O#P$Y#P#Q$Y#Q#R$Y#R#S$Y#S#T$Y#T#o$Y#o#p$Y#q#r$Y#r#s$Y$gBn$YBnBo(vBo&FV$Y&FV&FW({&FW;'S$Y;'S;=`&o<%l?Hb$Y?Hb?Hc)Q?HcO$Y~$TO!o~~$YO!n~~$_ti~qr$Ytu$Yuv$Yvw$Yxy$Yyz$Yz{$Y{|$Y}!O$Y!O!P$Y!P!Q$Y!Q![$Y![!]$Y!]!^$Y!^!_$Y!_!`$Y!`!a$Y!a!b$Y!b!c$Y!c!}$Y!}#O$Y#O#P$Y#P#Q$Y#Q#R$Y#R#S$Y#S#T$Y#T#o$Y#o#p$Y#q#r$Y#r#s$Y$gBn$YBo&FV$Y&FW;'S$Y;'S;=`&o<%l?Hb$Y?HcO$Y~&rP;=`<%l$Y~&xVOY&uZr&urs'_s#O&u#P;'S&u;'S;=`'d<%lO&u~'dOn~~'gP;=`<%l&u~'oSe~OY'jZ;'S'j;'S;=`'{<%lO'j~(OP;=`<%l'j~(UVOY(RZw(Rwx'_x#O(R#P;'S(R;'S;=`(k<%lO(R~(nP;=`<%l(R~(vO!p~~({O!q~~)QO!s~~)VO!r~",
  tokenizers: [0],
  topRules: {"Program":[0,22]},
  dynamicPrecedences: {"47":-10},
  specialized: [{term: 25, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 25, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 0
})
