// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  print = 1,
  is = 2,
  input = 3,
  sleep = 4,
  play = 5,
  random = 6,
  forward = 7,
  turn = 8,
  color = 9,
  add = 10,
  remove = 11,
  from = 12,
  clear = 13,
  ifs = 14,
  elses = 15,
  and = 16,
  or = 17,
  pressed = 18,
  notIn = 19,
  ins = 20,
  repeat = 21,
  times = 22,
  range = 23,
  whiles = 24,
  def = 25,
  returns = 26,
  fors = 27,
  to_list = 28,
  elif = 29,
  low_true = 30,
  low_false = 31,
  cap_true = 32,
  cap_false = 33,
  Comment = 34,
  Program = 35,
  Command = 36,
  Assign = 37,
  Text = 38,
  ListAccess = 39,
  Number = 41,
  Expression = 44,
  String = 45,
  Call = 46,
  Arguments = 48,
  AssignList = 53,
  Input = 54,
  Clear = 55,
  Print = 56,
  Play = 57,
  Turtle = 58,
  Sleep = 62,
  Add = 63,
  Remove = 64,
  If = 65,
  Condition = 66,
  Else = 76,
  Repeat = 77,
  For = 78,
  Define = 79,
  Return = 80,
  While = 81,
  Elif = 82,
  ErrorInvalid = 83
