// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  and = 7,
  or = 8,
  call = 9,
  _with = 10,
  print = 11,
  forward = 12,
  turn = 13,
  color = 14,
  sleep = 15,
  play = 16,
  is = 17,
  add = 18,
  from = 19,
  remove = 20,
  to_list = 21,
  clear = 22,
  ins = 23,
  not_in = 24,
  repeat = 25,
  times = 26,
  fors = 27,
  to = 28,
  range = 29,
  define = 30,
  returns = 31,
  Comment = 32,
  Program = 33,
  Command = 34,
  Assign = 35,
  Text = 36,
  ListAccess = 37,
  Number = 38,
  Expression = 40,
  String = 41,
  Call = 42,
  Arguments = 43,
  AssignList = 47,
  Ask = 48,
  Clear = 49,
  Print = 50,
  Play = 51,
  Turtle = 52,
  Sleep = 56,
  Add = 57,
  Remove = 58,
  If = 59,
  Condition = 60,
  Else = 64,
  Repeat = 65,
  For = 66,
  Define = 67,
  Return = 68,
  ErrorInvalid = 69
