// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  print = 4,
  forward = 5,
  turn = 6,
  color = 7,
  sleep = 8,
  play = 9,
  is = 10,
  add = 11,
  from = 12,
  remove = 13,
  to_list = 14,
  Comment = 15,
  Program = 16,
  Command = 17,
  Assign = 18,
  Text = 19,
  ListAccess = 20,
  AssignList = 21,
  Ask = 23,
  Print = 24,
  Play = 25,
  Turtle = 26,
  Sleep = 30,
  Add = 31,
  Remove = 32,
  ErrorInvalid = 33
