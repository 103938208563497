// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  and = 7,
  or = 8,
  is = 9,
  _while = 10,
  define = 11,
  _with = 12,
  low_true = 13,
  low_false = 14,
  cap_true = 15,
  cap_false = 16,
  print = 17,
  forward = 18,
  turn = 19,
  color = 20,
  sleep = 21,
  play = 22,
  add = 23,
  from = 24,
  remove = 25,
  to_list = 26,
  clear = 27,
  ins = 28,
  not_in = 29,
  repeat = 30,
  times = 31,
  fors = 32,
  to = 33,
  range = 34,
  call = 35,
  returns = 36,
  Comment = 37,
  Program = 38,
  Command = 39,
  Assign = 40,
  Text = 41,
  ListAccess = 42,
  Number = 43,
  Expression = 45,
  String = 46,
  Call = 47,
  Arguments = 48,
  AssignList = 52,
  Ask = 53,
  Clear = 54,
  Print = 55,
  Play = 56,
  Turtle = 57,
  Sleep = 61,
  Add = 62,
  Remove = 63,
  If = 64,
  Condition = 65,
  Else = 75,
  Repeat = 76,
  For = 77,
  Define = 78,
  Return = 79,
  While = 80,
  ErrorInvalid = 81
