// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  call = 7,
  _with = 8,
  print = 9,
  forward = 10,
  turn = 11,
  color = 12,
  sleep = 13,
  play = 14,
  is = 15,
  add = 16,
  from = 17,
  remove = 18,
  to_list = 19,
  clear = 20,
  ins = 21,
  not_in = 22,
  repeat = 23,
  times = 24,
  fors = 25,
  to = 26,
  range = 27,
  define = 28,
  returns = 29,
  Comment = 30,
  Program = 31,
  Command = 32,
  Assign = 33,
  Text = 34,
  ListAccess = 35,
  Number = 36,
  Expression = 38,
  String = 39,
  Call = 40,
  Arguments = 41,
  AssignList = 45,
  Ask = 46,
  Clear = 47,
  Print = 48,
  Play = 49,
  Turtle = 50,
  Sleep = 54,
  Add = 55,
  Remove = 56,
  If = 57,
  Condition = 58,
  Else = 62,
  Repeat = 63,
  For = 64,
  Define = 65,
  Return = 66,
  ErrorInvalid = 67
