// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  play = 12,
  is = 13,
  add = 14,
  from = 15,
  remove = 16,
  to_list = 17,
  clear = 18,
  ins = 19,
  not_in = 20,
  repeat = 21,
  times = 22,
  fors = 23,
  Comment = 24,
  Program = 25,
  Command = 26,
  Assign = 27,
  Text = 28,
  Expression = 30,
  Int = 31,
  ListAccess = 34,
  AssignList = 35,
  Ask = 37,
  String = 38,
  Clear = 39,
  Print = 40,
  Play = 41,
  Turtle = 42,
  Sleep = 46,
  Add = 47,
  Remove = 48,
  If = 49,
  Condition = 50,
  Else = 54,
  Repeat = 55,
  For = 56,
  ErrorInvalid = 57
