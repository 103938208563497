// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  print = 4,
  forward = 5,
  turn = 6,
  color = 7,
  sleep = 8,
  play = 9,
  is = 10,
  add = 11,
  from = 12,
  remove = 13,
  to_list = 14,
  clear = 15,
  Comment = 16,
  Program = 17,
  Command = 18,
  Assign = 19,
  Text = 20,
  ListAccess = 21,
  AssignList = 22,
  Ask = 24,
  String = 25,
  Clear = 26,
  Print = 27,
  Play = 28,
  Turtle = 29,
  Sleep = 33,
  Add = 34,
  Remove = 35,
  ErrorInvalid = 36
