// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  random = 2,
  ifs = 3,
  pressed = 4,
  elses = 5,
  and = 6,
  or = 7,
  is = 8,
  _while = 9,
  define = 10,
  _with = 11,
  low_true = 12,
  low_false = 13,
  cap_true = 14,
  cap_false = 15,
  print = 16,
  forward = 17,
  turn = 18,
  color = 19,
  sleep = 20,
  play = 21,
  add = 22,
  from = 23,
  remove = 24,
  to_list = 25,
  clear = 26,
  ins = 27,
  not_in = 28,
  repeat = 29,
  times = 30,
  fors = 31,
  to = 32,
  range = 33,
  call = 34,
  returns = 35,
  Comment = 36,
  Program = 37,
  Command = 38,
  Assign = 39,
  Text = 40,
  ListAccess = 41,
  Number = 43,
  Expression = 46,
  String = 47,
  Call = 48,
  Arguments = 49,
  AssignList = 53,
  Ask = 54,
  Clear = 55,
  Print = 56,
  Play = 57,
  Turtle = 58,
  Sleep = 62,
  Add = 63,
  Remove = 64,
  If = 65,
  Condition = 66,
  Else = 76,
  Repeat = 77,
  For = 78,
  Define = 79,
  Return = 80,
  While = 81,
  ErrorInvalid = 82
