// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  print = 1,
  forward = 2,
  turn = 3,
  echo = 4,
  play = 5,
  ask = 6,
  color = 7,
  Comment = 8,
  Program = 9,
  Command = 10,
  Print = 11,
  Text = 12,
  Ask = 13,
  Echo = 14,
  Play = 15,
  Turtle = 16,
  ErrorInvalid = 20
