// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  elses = 5,
  pressed = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  play = 12,
  is = 13,
  add = 14,
  from = 15,
  remove = 16,
  to_list = 17,
  clear = 18,
  ins = 19,
  not_in = 20,
  Comment = 21,
  Program = 22,
  Command = 23,
  Assign = 24,
  Text = 25,
  Expression = 27,
  Int = 28,
  ListAccess = 31,
  AssignList = 32,
  Ask = 34,
  String = 35,
  Clear = 36,
  Print = 37,
  Play = 38,
  Turtle = 39,
  Sleep = 43,
  Add = 44,
  Remove = 45,
  If = 46,
  Condition = 47,
  IfLessCommand = 51,
  ErrorInvalid = 52,
  Else = 53
