// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "4WQYQPOOOOQO'#Dj'#DjQYQPOOO!ZQPO'#DpO!lQPO'#DiOOQO'#Dt'#DtO!wQPO'#DVOOQO'#Du'#DuO#SQPO'#DWOOQO'#Dv'#DvO#bQPO'#DXOOQO'#Dw'#DwO#mQPO'#DZOOQO'#Dx'#DxO#xQPO'#D[OOQO'#Dy'#DyO$TQPO'#D]OOQO'#DY'#DYOOQO'#Dz'#DzO$`QPO'#D^OOQO'#D{'#D{O$qQPO'#D_OOQO'#D}'#D}O$yQPO'#D`OOQO'#EP'#EPO%RQPO'#DaOOQO'#ET'#ETO%^QPO'#DfOOQO'#EU'#EUO%iQPO'#DgOOQO'#EW'#EWO%tQPO'#DhOOQO'#Cx'#CxQ!UQPO'#DkQ%|QPOOOOQO-E7h-E7hOOQO'#Dl'#DlO&zQPO,59eOOQO'#C{'#C{O'YQPO,59eOOQO'#Dp'#DpOOQO-E7n-E7nOOQO-E7r-E7rO'eQPO'#DsOOQO'#C|'#C|O(qQPO'#C|OOQO'#Ds'#DsO(xQPO,59rOOQO-E7s-E7sO)ZQPO,59sOOQO-E7t-E7tOOQO,59s,59sO)oQPO,59uOOQO-E7u-E7uOOQO,59u,59uO*TQPO,59vOOQO-E7v-E7vOOQO,59v,59vO*iQPO,59wOOQO-E7w-E7wOOQO,59w,59wO*}QPO,59xOOQO-E7x-E7xOOQO,59x,59xO+cQPO,59yOOQO-E7y-E7yO+kQPO,59zOOQO-E7{-E7{OOQO-E7}-E7}O+sQPO'#DcOOQO'#Db'#DbOOQO,59{,59{OOQO-E8R-E8ROOQO-E8S-E8SO,RQPO,5:ROOQO-E8U-E8UO,WQPO,5:SO,]QPO,5:VOOQO-E7i-E7iOOQO-E7j-E7jO-aQPO'#DmO-{QPO1G/XO.]QPO'#C|OOQO'#Dm'#DmO.pQPO1G/PO/OQPO1G/XOOQO'#Dr'#DrO/aQPO1G/ZOOQO'#DO'#DOOOQO'#DP'#DPO/oQPO,5:zO/oQPO,5:zOOQO'#Dn'#DnO/wQPO,59lOOQO-E7q-E7qOOQO'#D|'#D|O0VQPO1G/eOOQO'#EO'#EOO0_QPO1G/fO0gQPO,59}O0xQPO,59}OOQO'#ER'#ERO1WQPO,5:OOOQO'#ES'#ESO1`QPO,5:POOQO'#EV'#EVO1hQPO1G/mO1sQPO1G/nOOQO'#DS'#DSO2OQPO'#DqO2WQPO7+$sOOQO-E7k-E7kOOQO-E7p-E7pO2lQPO7+$uOOQO1G0f1G0fO2}QPO1G0fOOQO-E7l-E7lOOQO'#Do'#DoO3lQPO1G/WOOQO1G/W1G/WOOQO-E7z-E7zOOQO7+%P7+%POOQO-E7|-E7|OOQO7+%Q7+%QO4QQPO1G/iOOQO'#EQ'#EQO4fQPO1G/iOOQO1G/i1G/iOOQO-E8P-E8POOQO1G/j1G/jOOQO-E8Q-E8QOOQO1G/k1G/kOOQO-E8T-E8TOOQO'#EX'#EXO4qQPO7+%YOOQO7+%Y7+%YO4|QPO,5:]OOQO,5:],5:]OOQO-E7o-E7oOOQO-E7m-E7mOOQO-E8O-E8OOOQO-E8V-E8VO5eQPO<<HtOOQO'#EY'#EYO5jQPOAN>`OOQO-E8W-E8WOOQOG23zG23z",
  stateData: "5x~OjOS#POS~OShOUjOVVOWZOX]OY_OZbO[XO^dO`fObTOelOgnOnRO#QPO~O]tO#RvOn!dX!}!dX#Q!dX~OnxO!}!]X#Q!]X~ObTO!}yX#QyX~OVVOn}Oq|Ox!OO~O[XOn}Oq|O~OWZOn}Oq|O~OX]On}Oq|O~OY_On}Oq|O~OZbOn}Oq|O!}!QX#Q!QX~O^dOnxO~O`fOnxO~OShOn!gOq!gO~OUjO!}!YX#Q!YX~OelOn!lOq!lO~OgnOn!nO~OShOUjOVVOWZOX]OY_OZbO[XO^dO`fObTOelOgnOnRO~OP!xO]tOn!tOq!sO~OP!xOn!tOq!sO~O#T!zO#U!zO#V!{O#W!{On!gXq!gXx!gX!}!gX#Q!gX~OQ#OOnpXqpX!}pX#QpX#TpX#UpX#VpX#WpX~OxpX~P(SOn}Oq|Ox!OO!}za#Qza~O#T!zO#U!zO#V!{O#W!{O!}{a#Q{a~O#T!zO#U!zO#V!{O#W!{O!}}a#Q}a~O#T!zO#U!zO#V!{O#W!{O!}!Oa#Q!Oa~O#T!zO#U!zO#V!{O#W!{O!}!Pa#Q!Pa~O#T!zO#U!zO#V!{O#W!{O!}!Qa#Q!Qa~Oa#ROnxO~O_#TOnxO~O]tOc#XOd#ZO#RvO~Of#]O~Oc#XO~O#QPOS!_aU!_aV!_aW!_aX!_aY!_aZ!_a[!_a^!_a`!_ab!_ae!_ag!_an!_a!}!_a~O#T!zO#U!zO#V!{O#W!{On!aXq!aX!}!aX#Q!aX~O#X#`O#Y#`O#Z#`O#[#`O~P(VOn!dX#X!dX#Y!dX#Z!dX#[!dX~P(SOn}Oq|O!}mi#Qmi~OnxO#X#`O#Y#`O#Z#`O#[#`O~OP!xOn}Oq|Ox!OO~On|Oq|O~OQ#OOR#iOn#kOq#kO~Oa#ROn#mO~O_#TOn#oO~OT#qO]tOn|Oq|Ox#sO~OT#qOn|Oq|Ox#sO~Oc#XOn#uO~Od#ZOn#wO~Of#]O!}!Zi#Q!Zi~Oc#XOi#yOn#{O~OnxOq#}O~O#X#`O#Y#`O#Z#`O#[#`O!}uq#Quq~On}Oq|Ox!OO!}wq#Qwq~O#T!zO#U!zO#V!{O#W!{On#Siq#Six#Si!}#Si#Q#Si~OR#iOntiqtixti!}ti#Qti~O#T!zO#U!zO#V!{O#W!{O!}!Vi#Q!Vi~OT#qO!}!Vi#Q!Vi~Oi#yOn$SOq$SO~OnxO!}!ea#Q!ea#X!ea#Y!ea#Z!ea#[!ea~Oh$TO~Oh$TOn$WOq$WO~Oqn~",
  goto: "+i#TPPPPPPPPPPPPPPPPPPPPPPPPPPPP#U#ZP#`#fP$[$g$r#Z%^#ZP#Z#Z#Z#Z%c%c%c#Z#Z#Z#Z%h%k%k%k#Z#Z#Z#Z%n%x&P&Z&b&i&o'Y'a'h'r'z(S([(d(l(t(|)U)[)d)j)r)y*T*Z*c*k*q*y+PPPPPP+VVqOQrVpOQrQwRR#W!gW{W!P!y#eQ!RYQ!U[Q!X^Q![`Q!_cU!ruw!vQ#f!|Q#g!}T#p#V#Wc!|{!R!U!X![!_!r#g#pc!}{!R!U!X![!_!r#g#pW!OW!P!y#eQ!TYQ!W[Q!Z^Q!^`Q!acV!uuw!vV#a!s!w#bVaOQrR!iiR!hiQQOSsQ!oR!oqSrOQR!prQuRS!qu#VR#V!gS!vuwR#c!vS#P}!tR#h#PQ#j#PR$P#jUSOQrYyS!b!d!w#|Q!beQ!dgS!wuwR#|#aS#b!s!wR$O#bS!yuwR#d!yQ!PWS#Q!P#eR#e!yUUOQrRzUUWOQrR!QWUYOQrR!SYU[OQrR!V[U^OQrR!Y^U`OQrR!]`UcOQrR!`cUeOQrR!ceQ#S!bR#l#SUgOQrR!egQ#U!dR#n#UUiOQrR!fiS#r#V#WR$Q#rQ#Y!gQ#_!nT#t#Y#_Q#[!gR#v#[UkOQrR!jkUmOQrR!kmQ#^!lR#x#^UoOQrR!moQ#z#_R$R#zQ$U$SR$V$Uq|WY[^`cuw!P!v!y!|!}#V#W#e",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep play is add from remove to_list clear in not_in repeat times for to range Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat For ErrorInvalid",
  maxTerm: 104,
  nodeProps: [
    ["group", 44,"turtle"]
  ],
  skippedNodes: [0,26],
  repeatNodeCount: 31,
  tokenData: "1l~R!nYZ&Ppq&Uqr&Zrs*hst+]tu&Zuv&Zvw&Zwx+txy&Zyz&Zz{,d{|,i|},n}!O,s!O!P&Z!P!Q,x!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!_!`1W!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBnBo1]BoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FV&FW1b&FW;'S&Z;'S;=`*b<%l?Hb&Z?Hb?Hc1g?HcO&Z~&UO#Q~~&ZO#P~~&`!`n~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R&Z!R!S&Z!S!T&Z!T!U&Z!U!V&Z!V!W&Z!W!X&Z!X!Y&Z!Y!Z&Z!Z![&Z![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg&ZDgDh&ZDhDi&ZDiDj&ZDjDk&ZDkDl&ZDlDm&ZDmDn&ZDnDo&ZDoDp&ZDvGl&ZGlGm&ZGmGn&ZGnGo&ZGoGp&ZGpGq&ZGqGr&ZGrGs&ZGsGt&ZGtGu&ZGuGv&ZGv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~*eP;=`<%l&Z~*kVOY*hZr*hrs+Qs#O*h#P;'S*h;'S;=`+V<%lO*h~+VOx~~+YP;=`<%l*h~+bSj~OY+]Z;'S+];'S;=`+n<%lO+]~+qP;=`<%l+]~+wVOY+tZw+twx+Qx#O+t#P;'S+t;'S;=`,^<%lO+t~,aP;=`<%l+t~,iO#T~~,nO#V~~,sO#X~~,xO#W~~,}O#U~~-U!`q~n~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~1]O#R~~1bO#Y~~1gO#[~~1lO#Z~",
  tokenizers: [0],
  topRules: {"Program":[0,27]},
  dynamicPrecedences: {"59":-10},
  specialized: [{term: 30, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 30, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 957
})
