// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  play = 12,
  is = 13,
  add = 14,
  from = 15,
  remove = 16,
  to_list = 17,
  clear = 18,
  ins = 19,
  not_in = 20,
  repeat = 21,
  times = 22,
  Comment = 23,
  Program = 24,
  Command = 25,
  Assign = 26,
  Text = 27,
  Expression = 29,
  Int = 30,
  ListAccess = 33,
  AssignList = 34,
  Ask = 36,
  String = 37,
  Clear = 38,
  Print = 39,
  Play = 40,
  Turtle = 41,
  Sleep = 45,
  Add = 46,
  Remove = 47,
  If = 48,
  Condition = 49,
  IfLessCommand = 53,
  Repeat = 54,
  ErrorInvalid = 55,
  Else = 56
