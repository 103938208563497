// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  print = 2,
  forward = 3,
  turn = 4,
  color = 5,
  sleep = 6,
  play = 7,
  is = 8,
  Comment = 9,
  SpecialChar = 10,
  Program = 11,
  Command = 12,
  Assign = 13,
  Text = 14,
  Ask = 15,
  Print = 16,
  Play = 17,
  Turtle = 18,
  Sleep = 22,
  ErrorInvalid = 23
