// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  random = 2,
  ifs = 3,
  pressed = 4,
  elses = 5,
  and = 6,
  or = 7,
  is = 8,
  _while = 9,
  elif = 10,
  define = 11,
  _with = 12,
  low_true = 13,
  low_false = 14,
  cap_true = 15,
  cap_false = 16,
  print = 17,
  forward = 18,
  turn = 19,
  color = 20,
  sleep = 21,
  play = 22,
  add = 23,
  from = 24,
  remove = 25,
  to_list = 26,
  clear = 27,
  ins = 28,
  not_in = 29,
  repeat = 30,
  times = 31,
  fors = 32,
  to = 33,
  range = 34,
  call = 35,
  returns = 36,
  Comment = 37,
  Program = 38,
  Command = 39,
  Assign = 40,
  Text = 41,
  ListAccess = 42,
  Number = 44,
  Expression = 47,
  String = 48,
  Call = 49,
  Arguments = 50,
  AssignList = 54,
  Ask = 55,
  Clear = 56,
  Print = 57,
  Play = 58,
  Turtle = 59,
  Sleep = 63,
  Add = 64,
  Remove = 65,
  If = 66,
  Condition = 67,
  Else = 78,
  Repeat = 79,
  For = 80,
  Define = 81,
  Return = 82,
  While = 83,
  Elif = 84,
  ErrorInvalid = 85
