// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "3bQYQPOOOOQO'#Dg'#DgQYQPOOO!WQPO'#DmO!lQPO'#DeOOQO'#Dq'#DqO!zQPO'#DSO#YQPO'#DTOOQO'#Dr'#DrO#eQPO'#DUOOQO'#Ds'#DsO#pQPO'#DWOOQO'#Dt'#DtO#{QPO'#DXOOQO'#Du'#DuO$WQPO'#DYOOQO'#DV'#DVOOQO'#Dv'#DvO$cQPO'#DZOOQO'#Dw'#DwO$wQPO'#D[OOQO'#Dy'#DyO%PQPO'#D]OOQO'#D{'#D{O%XQPO'#D^OOQO'#EP'#EPO%dQPO'#DdOOQO'#ER'#ERO&gQPO'#DfOOQO'#Cu'#CuQ!RQPO'#DhQ&nQPOOOOQO-E7e-E7eOOQO'#Di'#DiO&uQPO,59bOOQO'#Cx'#CxO'TQPO,59bOOQO'#Dm'#DmOOQO-E7k-E7kOOQO-E7o-E7oO'`QPO'#DpOOQO'#Cy'#CyO(rQPO'#CyOOQO'#Dp'#DpO(yQPO,59oO)_QPO,59pOOQO-E7p-E7pOOQO,59p,59pO)vQPO,59rOOQO-E7q-E7qOOQO,59r,59rO*_QPO,59sOOQO-E7r-E7rOOQO,59s,59sO*vQPO,59tOOQO-E7s-E7sOOQO,59t,59tO+_QPO,59uOOQO-E7t-E7tOOQO,59u,59uO+vQPO,59vOOQO-E7u-E7uO,OQPO,59wOOQO-E7w-E7wOOQO-E7y-E7yO,WQPO'#D`OOQO'#D_'#D_O`QPO,59xOOQO-E7}-E7}O,fQPO,5:OO`QPO,5:QOOQO'#Dc'#DcOOQO-E8P-E8POOQO,5:Q,5:QO,kQPO,5:SOOQO-E7f-E7fOOQO-E7g-E7gO-lQPO'#DjO.ZQPO1G/UO.kQPO'#CyOOQO'#Dj'#DjO/OQPO1G.|O/aQPO1G/UOOQO'#Do'#DoO/rQPO1G/WOOQO'#C{'#C{OOQO'#C|'#C|O0QQPO,5:sO0QQPO,5:sOOQO'#Dk'#DkO0YQPO,59iOOQO-E7n-E7nOOQO'#Dx'#DxO0hQPO1G/bOOQO'#Dz'#DzO0pQPO1G/cO0xQPO,59zO1ZQPO,59zOOQO'#D}'#D}O1iQPO,59{OOQO'#EO'#EOO1qQPO,59|O`QPO1G/dO1yQPO1G/dOOQO'#EQ'#EQO2UQPO1G/jOOQO1G/l1G/lOOQO'#DP'#DPO2fQPO'#DnO2nQPO7+$pOOQO-E7h-E7hOOQO-E7m-E7mO3VQPO7+$rOOQO1G0_1G0_O3kQPO1G0_OOQO-E7i-E7iOOQO'#Dl'#DlO4{QPO1G/TOOQO1G/T1G/TOOQO-E7v-E7vOOQO7+$|7+$|OOQO-E7x-E7xOOQO7+$}7+$}O6[QPO1G/fOOQO'#D|'#D|O6lQPO1G/fOOQO1G/f1G/fOOQO-E7{-E7{OOQO1G/g1G/gOOQO-E7|-E7|OOQO1G/h1G/hO6sQPO7+%OOOQO7+%O7+%OOOQO-E8O-E8OOOQO7+%U7+%UO7OQPO,5:YOOQO,5:Y,5:YOOQO-E7l-E7lOOQO-E7j-E7jOOQO-E7z-E7zOOQO<<Hj<<Hj",
  stateData: "7m~OgOS!xOS~OSgOUkOVVOWYOX[OY^OZaO[WO^cO`eObTOeiOkRO!yPO~O]qO!zsOk!aX!v!aX!y!aXU!aX~OkuO!v!XX!y!XXU!XX~ObTO!vvX!yvXUvX~OkzOnyOu{O~O[WOkzOnyO~OWYOkzOnyO~OX[OkzOnyO~OY^OkzOnyO~OZaOkzOnyO!v}X!y}XU}X~O^cOkuO~O`eOkuO~OSgOk!cOn!cO~OeiOk!gOn!gO~OUkOVVOWYOX[OY^OZaO[WO^cO`eObTOeiOkRO~O!yPO~P%oOSgO~P%oOP!uO]qOk!qOn!pO~OP!uOk!qOn!pO~O!|!wO!}!wO#O!xO#P!xOk!dXn!dXu!dX!v!dX!y!dXU!dX~OQ!{OkmXnmX!vmX!ymX!|mX!}mX#OmX#PmXUmX~OumX~P(QOkzOnyOu{O!vwa!ywaUwa~O!|!wO!}!wO#O!xO#P!xO!vxa!yxaUxa~O!|!wO!}!wO#O!xO#P!xO!vza!yzaUza~O!|!wO!}!wO#O!xO#P!xO!v{a!y{aU{a~O!|!wO!}!wO#O!xO#P!xO!v|a!y|aU|a~O!|!wO!}!wO#O!xO#P!xO!v}a!y}aU}a~Oa#OOkuO~O_#QOkuO~O]qOc#UOd#WO!zsO~Of#[O~O!yPOS![aU![aV![aW![aX![aY![aZ![a[![a^![a`![ab![ae![ak![a!v![a~O!|!wO!}!wO#O!xO#P!xOk!^Xn!^X!v!^X!y!^XU!^X~O#Q#_O#R#_O#S#_O#T#_O~P(TOk!aX#Q!aX#R!aX#S!aX#T!aX~P(QOkzOnyO!vji!yjiUji~OkuO#Q#_O#R#_O#S#_O#T#_O~OP!uOkzOnyOu{O~OkyOnyO~OQ!{OR#hOk#jOn#jO~Oa#OOk#lO~O_#QOk#nO~OT#pO]qOkyOnyOu#rO~OT#pOkyOnyOu#rO~Oc#UOk#tO~Od#WOk#vO~OUkO!v!Qi!y!Qi~OSgOf#[O!v!Wi!y!Wi~P%oOkuOn#|O~O#Q#_O#R#_O#S#_O#T#_O!vrq!yrqUrq~OkzOnyOu{O!vtq!ytqUtq~O!|!wO!}!wO#O!xO#P!xOk!{in!{iu!{i!v!{i!y!{iV!{iW!{iX!{iY!{iZ!{i[!{i^!{i`!{ib!{ie!{iU!{i~OR#hOkqinqiuqi!vqi!yqiUqi~OV!SiW!SiX!SiY!SiZ!Si[!Si^!Si`!Sib!Sie!Sik!Si!y!Si~O!|!wO!}!wO#O!xO#P!xO~P5dOT#pO~P5dOUkO!v!Qq!y!Qq~OkuO!v!ba!y!ba#Q!ba#R!ba#S!ba#T!baU!ba~Onk~",
  goto: "-P!|PPPPPPPPPPPPPPPPPPPPPPPPP!}#VP#c#iP$_$j$u#V%a#VP#V#V#V#V%f%f%f#V#V#V%p%v%y%y%y%|#V#V&Y&f&x'P'Z'b'i'o(_(f(m(w)U)c)p)}*[*i*v*|+Z+a+j+q+w+},[,bPPPPP,mUnOQoR#z#]WmOQo#]X!il!e!h#YQtRR#T!cWxV|!v#dQ}XQ!QZQ!T]Q!W_Q!ZbU!ort!sQ#e!yQ#f!zT#o#S#Tc!yx}!Q!T!W!Z!o#f#oc!zx}!Q!T!W!Z!o#f#oW{V|!v#dQ!PXQ!SZQ!V]Q!Y_Q!]bV!rrt!sV#`!p!t#aa`OQlo!e!h#Y#]XmOQo#]R!ehR!dhQ!klQ#Z!eQ#^!hR#w#YWmOQo#]Q#x#ZR$Q#wQQOWpQ!h!l#YQ!hlQ!lnR#Y!eSoOQR!moQrRS!nr#SR#S!cS!srtR#b!sS!|z!qR#g!|Q#i!|R$O#i`SOQlo!e!h#Y#]YvS!^!`!t#{Q!^dQ!`fS!trtR#{#`S#a!p!tR#}#aS!vrtR#c!vQ|VS!}|#dR#d!v`UOQlo!e!h#Y#]RwU`XOQlo!e!h#Y#]R!OX`ZOQlo!e!h#Y#]R!RZ`]OQlo!e!h#Y#]R!U]`_OQlo!e!h#Y#]R!X_`bOQlo!e!h#Y#]R![b`dOQlo!e!h#Y#]R!_dQ#P!^R#k#P`fOQlo!e!h#Y#]R!afQ#R!`R#m#RWhOQo#]R!bhS#q#S#TR$P#qQ#V!cR#s#VQ#X!cR#u#X`jOQlo!e!h#Y#]R!fjQ#]!gR#y#][lOQo#Z#]#wR!jlqyVXZ]_brt|!s!v!y!z#S#T#d",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep play is add from remove to_list clear in not_in repeat times Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand Repeat ErrorInvalid Else",
  maxTerm: 97,
  nodeProps: [
    ["group", 41,"turtle"]
  ],
  skippedNodes: [0,23],
  repeatNodeCount: 27,
  tokenData: "1l~R!nYZ&Ppq&Uqr&Zrs*hst+]tu&Zuv&Zvw&Zwx+txy&Zyz&Zz{,d{|,i|},n}!O,s!O!P&Z!P!Q,x!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!_!`1W!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBnBo1]BoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FV&FW1b&FW;'S&Z;'S;=`*b<%l?Hb&Z?Hb?Hc1g?HcO&Z~&UO!y~~&ZO!x~~&`!`k~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R&Z!R!S&Z!S!T&Z!T!U&Z!U!V&Z!V!W&Z!W!X&Z!X!Y&Z!Y!Z&Z!Z![&Z![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg&ZDgDh&ZDhDi&ZDiDj&ZDjDk&ZDkDl&ZDlDm&ZDmDn&ZDnDo&ZDoDp&ZDvGl&ZGlGm&ZGmGn&ZGnGo&ZGoGp&ZGpGq&ZGqGr&ZGrGs&ZGsGt&ZGtGu&ZGuGv&ZGv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~*eP;=`<%l&Z~*kVOY*hZr*hrs+Qs#O*h#P;'S*h;'S;=`+V<%lO*h~+VOu~~+YP;=`<%l*h~+bSg~OY+]Z;'S+];'S;=`+n<%lO+]~+qP;=`<%l+]~+wVOY+tZw+twx+Qx#O+t#P;'S+t;'S;=`,^<%lO+t~,aP;=`<%l+t~,iO!|~~,nO#O~~,sO#Q~~,xO#P~~,}O!}~~-U!`n~k~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~1]O!z~~1bO#R~~1gO#T~~1lO#S~",
  tokenizers: [0],
  topRules: {"Program":[0,24]},
  dynamicPrecedences: {"55":-10},
  specialized: [{term: 27, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 27, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 1038
})
