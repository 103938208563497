// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "+|QYQPOOOOQO'#DO'#DOQYQPOOOzQPO'#DRO!YQPO'#C}OOQO'#DW'#DWO!eQPO'#CtOOQO'#DY'#DYO!mQPO'#CuOOQO'#DZ'#DZO!uQPO'#CwOOQO'#D['#D[O!}QPO'#CxOOQO'#D]'#D]O#VQPO'#CyOOQO'#Cv'#CvOOQO'#D^'#D^O#_QPO'#CzOOQO'#D_'#D_O#mQPO'#C{OOQO'#Da'#DaO#uQPO'#C|OOQO'#Cm'#CmQuQPO'#DPQ#}QPOOOOQO-E6|-E6|OOQO'#DQ'#DQO$lQPO,59YOOQO'#DR'#DROOQO-E7P-E7PO$wQPO'#DXOOQO-E7U-E7UOOQO'#DX'#DXO%VQPO,59`O%bQPO'#DRO%|QPO,59aOOQO-E7W-E7WOOQO,59a,59aO&XQPO,59cOOQO-E7X-E7XOOQO,59c,59cO&dQPO,59dOOQO-E7Y-E7YOOQO,59d,59dO&oQPO,59eOOQO-E7Z-E7ZOOQO,59e,59eO&zQPO,59fOOQO-E7[-E7[OOQO,59f,59fO'VQPO,59gOOQO-E7]-E7]O'_QPO,59hOOQO-E7_-E7_O'gQPO,59kOOQO-E6}-E6}OOQO-E7O-E7OO([QPO1G.tOOQO1G.t1G.tOOQO'#DV'#DVO(sQPO1G.yOOQO'#DS'#DSO({QPO,59[OOQO-E7V-E7VOOQO'#D`'#D`O)WQPO1G/ROOQO'#Db'#DbO)`QPO1G/SOOQO'#Cr'#CrO#pQPO'#DUO)hQPO7+$cO)|QPO7+$eOOQO-E7T-E7TOOQO-E7Q-E7QOOQO'#DT'#DTO*XQPO1G.vOOQO1G.v1G.vOOQO-E7^-E7^OOQO7+$m7+$mOOQO-E7`-E7`OOQO7+$n7+$nO*gQPO,59pOOQO-E7S-E7SOOQO-E7R-E7R",
  stateData: "+O~O_OS!XOS~OSTOTXOUZOV]OW`OXVOZbO]dOcRO!YPO~OYjOcuX!VuX!YuX~OclO!VqX!YqX~OSTOcnO~OXVOcrO~OTXOcvO~OUZOcyO~OV]Oc|O~OW`Oc!PO!VnX!YnX~OZbOclO~O]dOclO~OSTOTXOUZOV]OW`OXVOZbO]dOcRO~OP!]OYjOcrO~OQ!_Oc{X!V{X!Y{X~OcnO!Vha!Yha~OQ!_OcuX!VuX!YuX!ZuX![uX!]uX!^uX~OclO!Via!Yia~OQ!_O!Vka!Yka~OQ!_O!Vla!Yla~OQ!_O!Vma!Yma~OQ!_O!Vna!Yna~O^!bOclO~O[!dOclO~O!YPOSsaTsaUsaVsaWsaXsaZsa]sacsa!Vsa~OclO!Z!fO![!fO!]!fO!^!fO!Vbi!Ybi~OP!]OclO~OQ!_OR!lOc!nO~O^!bOc!pO~O[!dOc!rO~O!Z!fO![!fO!]!fO!^!fO!Veq!Yeq~OclO!Vgq!Ygq~OR!lOcdi!Vdi!Ydi~OclO!Vxa!Yxa!Zxa![xa!]xa!^xa~O",
  goto: "&o!VPPPPPPPPPPPPPPPPP!W!]P!b!]!x!]!]!]!]!|!|!|!]!]!]!]#R#]#d#j$[$g$m$s$y%R%X%a%i%q%y&R&Z&a&iVgOQhVfOQhSpUqQuWQxYQ{[Q!O^Q!RaR![kT!g!Z!hV_OQhQQOSiQ!WR!WgShOQR!XhQkRR!YkUSOQh^mSs!S!U!Z!i!sQsWQ!ScQ!UeQ!ZkQ!i!^R!s!g[!`nrvy|!PR!k!`Q!m!`R!u!mQ!h!ZR!t!hQ!^kR!j!^UUOQhRoUQqUR!aqUWOQhRtWUYOQhRwYU[OQhRz[U^OQhR}^UaOQhR!QaUcOQhR!TcQ!c!SR!o!cUeOQhR!VeQ!e!UR!q!e",
  nodeNames: "⚠ ask at random print forward turn color sleep play is add from remove to_list Comment Program Command Assign Text ListAccess AssignList Op Ask Print Play Turtle Forward Turn Color Sleep Add Remove ErrorInvalid",
  maxTerm: 60,
  nodeProps: [
    ["group", 26,"turtle"]
  ],
  skippedNodes: [0,15],
  repeatNodeCount: 19,
  tokenData: "$`~RbOY!ZYZ#XZp!Zpq#^qs!Zst#ct|!Z|}#z}#Q!Z#RBn!ZBnBo$PBo&FV!Z&FV&FW$U&FW;'S!Z;'S;=`#R<%l?Hb!Z?Hb?Hc$Z?HcO!Z~!`Zc~OY!ZZp!Zqs!Zt|!Z}#Q!Z#RBn!ZBo&FV!Z&FW;'S!Z;'S;=`#R<%l?Hb!Z?HcO!Z~#UP;=`<%l!Z~#^O!Y~~#cO!X~~#hS_~OY#cZ;'S#c;'S;=`#t<%lO#c~#wP;=`<%l#c~$PO!Z~~$UO![~~$ZO!^~~$`O!]~",
  tokenizers: [0],
  topRules: {"Program":[0,16]},
  dynamicPrecedences: {"33":-10},
  specialized: [{term: 19, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 19, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 0
})
