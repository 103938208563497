// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  play = 12,
  is = 13,
  add = 14,
  from = 15,
  remove = 16,
  to_list = 17,
  clear = 18,
  ins = 19,
  not_in = 20,
  repeat = 21,
  times = 22,
  fors = 23,
  to = 24,
  range = 25,
  Comment = 26,
  Program = 27,
  Command = 28,
  Assign = 29,
  Text = 30,
  Expression = 32,
  Int = 33,
  ListAccess = 36,
  AssignList = 37,
  Ask = 39,
  String = 40,
  Clear = 41,
  Print = 42,
  Play = 43,
  Turtle = 44,
  Sleep = 48,
  Add = 49,
  Remove = 50,
  If = 51,
  Condition = 52,
  Else = 56,
  Repeat = 57,
  For = 58,
  ErrorInvalid = 59
