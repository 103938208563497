// This file has been generated by build-tools/heroku/generate-client-messages.py
// DO NOT EDIT

export type MessageKey = 'CheckInternet' | 'Connection_error' | 'Empty_output' | 'Errors_found' | 'Execute_error' | 'Other_error' | 'Program_repair' | 'Program_too_long' | 'ServerError' | 'Transpile_error' | 'Transpile_success' | 'Transpile_warning' | 'Unsaved_Changes' | 'adventures_completed' | 'adventures_restored' | 'adventures_tried' | 'copy_link_to_share' | 'customization_deleted' | 'dice' | 'directly_available' | 'disabled' | 'errors' | 'fortune' | 'graph_title' | 'haunted' | 'level_title' | 'multiple_keywords_warning' | 'number_of_errors' | 'one_level_error' | 'restaurant' | 'rock' | 'select_all' | 'selected' | 'songs' | 'successful_runs' | 'teacher_welcome' | 'turtle' | 'unsaved_class_changes'
export const TRANSLATIONS: Record<string, Record<MessageKey, string>> = {
  "ar": {
    "CheckInternet": "ألقي نظرة إذا كان اتصالك بالإنترنت يعمل بشكل صحيح.",
    "Connection_error": "لم نتمكن من الوصول إلى الخادم.",
    "Empty_output": "هذا الرمز يعمل ولكن لا يطبع أي شيء. أضف أمر طباعة إلى الكود الخاص بك أو استخدم السلحفاة لطباعة شيء ما.",
    "Errors_found": "لقد قمت بخطأ! لا تقلق، لقد قمنا بتشغيل البرنامج",
    "Execute_error": "حدث خطأ ما أثناء تشغيل البرنامج.",
    "Other_error": "عفوا! ربما ارتكبنا خطأ بسيطا.",
    "Program_repair": "قد يكون هذا هو الرمز الصحيح ، هل يمكنك إصلاحه؟",
    "Program_too_long": "يستغرق برنامجك وقتًا طويلاً للتشغيل.",
    "ServerError": "لقد كتبت برنامجا لم نكن نتوقعه. إذا كنت ترغب في المساعدة ، فأرسل لنا بريدا إلكترونيا يحتوي على المستوى والبرنامج الخاص بك على hello@hedy.org. في غضون ذلك ، جرب شيئا مختلفا قليلا وألق نظرة أخرى على الأمثلة. شكرا!",
    "Transpile_error": "لا يمكننا تشغيل برنامجك.",
    "Transpile_success": "أحسنت!\nرائع!\nأحسنت!\nممتاز!\nلقد قمت بعمل رائع!",
    "Transpile_warning": "تحذير!",
    "Unsaved_Changes": "لديك برنامج غير محفوظ. هل تريد المغادرة دون حفظه؟",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "انسخ الرابط لمشاركته",
    "customization_deleted": "تم حذف التخصيصات بنجاح.",
    "dice": "🎲",
    "directly_available": "افتتح مباشرة",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "مستوى",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "مرحباً في هيدي! بكل فخر أنت الآن تمتلك حساباً كمعلم وذا يسمح لك بانشاء صفوف ودعوة تلاميذ.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "bg": {
    "CheckInternet": "Провери дали имаш връзка с интернет.",
    "Connection_error": "Изгубихме връзка със сървъра.",
    "Empty_output": "Кодът работи, но няма нищо за принтиране.",
    "Errors_found": "Допусна грешка! Не се тревожи, ние успяхме да изпълним програмата",
    "Execute_error": "Нещо се обърка при изпълнението на програмата.",
    "Other_error": "Ой! Май имаме някаква грешка в кода.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Птограмата ти се изпълнява прекалено дълго време...",
    "ServerError": "Написал(а) си код, който не очаквахме. Помогни ни и ни изпрати имейл на hello@hedy.org с името на нивото, в което заседна. Предварително благодарим!",
    "Transpile_error": "Програмата ти не може да бъде изпълнена.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Внимание!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Ниво",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "bn": {
    "CheckInternet": "Check whether your Internet connection is working।",
    "Connection_error": "We couldn't reach the server।",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output।",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong।",
    "Other_error": "Oops! Maybe we made a little mistake।",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run।",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program।",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ca": {
    "CheckInternet": "Mireu si la teva connexió a Internet funciona correctament.",
    "Connection_error": "No hem pogut arribar al servidor.",
    "Empty_output": "Aquest codi funciona, però no imprimeix res. Afegiu una comanda d'impressió al vostre codi o utilitzeu la tortuga per obtenir la sortida.",
    "Errors_found": "Has comès un error! No et preocupis, encara podem executar el programa",
    "Execute_error": "Alguna cosa ha anat malament.",
    "Other_error": "Vaja! Potser ens hem equivocat una mica.",
    "Program_repair": "Aquest podria ser el codi correcte, el pots arreglar?",
    "Program_too_long": "El vostre programa triga massa a executar-se.",
    "ServerError": "Ens pots ajudar enviant un correu electrònic amb el nivell i el teu programa a hello@hedy.org. Mentrestant, prova alguna cosa una mica diferent. Gràcies!",
    "Transpile_error": "No podem executar el vostre programa.",
    "Transpile_success": "Ben fet!\nIncreïble!\nBen fet!\nExcel·lent!\nHo has fet genial!",
    "Transpile_warning": "Vigila!",
    "Unsaved_Changes": "Tens un programa sense desar. Vols marxar sense desar-lo?",
    "adventures_completed": "Aventures completades: {number_of_adventures}",
    "adventures_restored": "Les aventures per defecte han estat restaurades.",
    "adventures_tried": "Aventures intentades",
    "copy_link_to_share": "Copia l'enllaç per compartir",
    "customization_deleted": "Personalitzacions esborrades correctament.",
    "dice": "🎲",
    "directly_available": "Obrir directament",
    "disabled": "Desactivat",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nivell",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵, 🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Benvinguda a Hedy! El teu compte s'ha actualitzat a compte de professorat. Això et permetrà crear classes i convidar alumnes.",
    "turtle": "🐢",
    "unsaved_class_changes": "Hi ha canvis no guardats, segur que voleu sortir d'aquesta pàgina?"
  },
  "cs": {
    "CheckInternet": "Prosím, zkontroluj své připojení k Internetu.",
    "Connection_error": "Nelze se připojit k serveru.",
    "Empty_output": "Tento kód funguje, ale nemá žádný výstup. Použij příkaz print do kódu nebo použij želvu k získání výstupu.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Při provádění programu se něco pokazilo.",
    "Other_error": "Jejda! Asi jsme někde udělali menší chybu.",
    "Program_repair": "Tohle by mohl být správný zápis kódu, můžeš to opravit?",
    "Program_too_long": "Tvůj program běží přílíš dlouho.",
    "ServerError": "Napsal*a jsi program, který jsme nečekali. Pokud potřebuješ pomoc, pošli nám e-mail s číslem úrovně a svým kódem na hello@hedy.org. Mezitím můžeš zkusit úkol vyřešit nějak jinak nebo se znova podívat na příklady. Díky!",
    "Transpile_error": "Nelze spustit tvůj program.",
    "Transpile_success": "Dobrá práce!\nÚžasné!\nSkvělé!\nVýtečné!\nVedeš si výborně!",
    "Transpile_warning": "Pozor!",
    "Unsaved_Changes": "Tvůj program není uložený. Chceš odejít bez uložení?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Zkopírovat odkaz ke sdílení",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Úroveň",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "cy": {
    "CheckInternet": "Edrychwch os yw eich cysylltiad rhyngrwyd yn gweithio'n iawn.",
    "Connection_error": "Ni allem gyrraedd y gweinydd.",
    "Empty_output": "Mae'r cod hwn yn gweithio ond nid yw'n argraffu unrhyw beth. Ychwanegwch orchymyn argraffu i'ch cod neu defnyddiwch y crwban i gael allbwn.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Aeth rhywbeth o'i le wrth redeg y rhaglen.",
    "Other_error": "Ŵps! Efallai ein bod wedi gwneud camgymeriad bach.",
    "Program_repair": "Gallai hwn fod y cod cywir, allwch chi ei drwsio?",
    "Program_too_long": "Mae eich rhaglen yn cymryd gormod o amser i'w rhedeg.",
    "ServerError": "Fe wnaethoch chi ysgrifennu rhaglen nad oeddem yn ei disgwyl. Os ydych chi eisiau helpu, anfonwch e-bost atom gyda'r lefel a'ch rhaglen yn hello@hedy.org. Yn y cyfamser, rhowch gynnig ar rywbeth ychydig yn wahanol ac edrychwch eto ar yr enghreifftiau. Diolch!",
    "Transpile_error": "Ni allwn redeg eich rhaglen.",
    "Transpile_success": "Gwaith da!\nAnhygoel!\nDa iawn!\nArdderchog!\nFe wnaethoch chi waith gwych!",
    "Transpile_warning": "Rhybudd!",
    "Unsaved_Changes": "Mae gennych raglen heb ei chadw. Ydych chi eisiau gadael heb ei arbed?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "da": {
    "CheckInternet": "Tjek om du er forbundet til internettet.",
    "Connection_error": "Vi kunne ikke nå serveren.",
    "Empty_output": "Denne kode fungerer, men udskriver ikke noget. Tilføj en udskriftskommando til din kode eller brug skildpadden til at få et output.",
    "Errors_found": "Du lavede en fejl! Bare rolig, vi kørte stadig programmet",
    "Execute_error": "Noget gik galt da programmet kørte.",
    "Other_error": "Ups! Vi har måske lavet en lille fejl.",
    "Program_repair": "Dette kunne være den korrekte kode; kan du løse det?",
    "Program_too_long": "Dit program tager for lang tid at køre.",
    "ServerError": "Du skrev et program, vi ikke havde forventet. Hvis du ønsker at hjælpe, sende os en e-mail med niveauet og dit program på hello@hedy.org. I mellemtiden, prøv at test et lidt anderledes program og tage et ekstra kig på eksemplerne. Tak!",
    "Transpile_error": "Vi kan ikke køre dit program.",
    "Transpile_success": "Godt arbejde!\nFantastisk!\nGodt klaret!\nFremragende!\nFlot gjort!",
    "Transpile_warning": "Advarsel!",
    "Unsaved_Changes": "Dit program er ikke gemt. Vil du afslutte uden at gemme det?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "de": {
    "CheckInternet": "Überprüfe, ob deine Internetverbindung funktioniert.",
    "Connection_error": "Wir konnten den Server nicht erreichen.",
    "Empty_output": "Dieses Programm funktioniert aber gibt nichts aus. Füge ein `print` Befehl hinzu oder benutz die Schildkröt um eine Ausgabe zu bekommen.",
    "Errors_found": "Du hast einen Fehler gemacht! Keine Sorge, wir haben das Programm trotzdem ausgeführt",
    "Execute_error": "Etwas ist beim Ausführen des Programms schiefgegangen.",
    "Other_error": "Hoppla! Vielleicht haben wir einen kleinen Fehler gemacht.",
    "Program_repair": "Das könnte der korrekte Code sein, kannst du es beheben?",
    "Program_too_long": "Dein Programm ist zu lang um es auszuführen.",
    "ServerError": "Du hast ein Programm geschrieben das wir nicht erwartet haben. Wenn du uns hlefen möchtest, dann sende eine Email an hello@hedy.org und gib darin das Level an in dem du dieses Programm geschrieben hast und füge den Programmcode hinzu. In der Zwischenzeit, probiere aus es ein bisschen anders zu formulieren und schau nochmal in die Beispiele. Danke!",
    "Transpile_error": "Wir konnten dein Hedy-Programm nicht lesen.",
    "Transpile_success": "Gut gemacht!\nToll!\nSehr gut gemacht!\nAusgezeichnet!\nDas hast du großartig gemacht!",
    "Transpile_warning": "Warnung!",
    "Unsaved_Changes": "Du hast ein nicht gespeichertes Programm. Möchtest du es verwerfen, ohne zu speichern?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Die Standardabenteuer wurden wiederhergestellt.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Kopiere Link zum Weitergeben",
    "customization_deleted": "Anpassungen erfolgreich gelöscht.",
    "dice": "🎲",
    "directly_available": "Direkt öffnen",
    "disabled": "Deaktiviert",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Willkommen bei Hedy! Du bist nun eine stolze BesitzerIn eines LehrerIn Kontos, welches dir erlaubt Klassen zu erstellen und Schüler einzuladen.",
    "turtle": "🐢",
    "unsaved_class_changes": "Es gibt ungespeicherte Änderungen, bist du dir sicher, dass du diese Seite verlassen möchtest?"
  },
  "el": {
    "CheckInternet": "Ρίξε μια ματιά αν η σύνδεση σου στο Διαδίκτυο δουλεύει σωστά.",
    "Connection_error": "Δεν μπορέσαμε να βρούμε το διακομιστή.",
    "Empty_output": "Αυτός ο κώδικας λειτουργεί αλλά δεν εμφανίζει κάτι. Πρόσθεσε μια εντολή εμφάνισης στον κώδικά σου ή χρησιμοποίησε τη χελώνα για να έχεις έξοδο.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Κάτι πήγε στραβά όσο εκτελούσαμε το πρόγραμμα.",
    "Other_error": "Ουπς! Ίσως να κάναμε κάποιο λαθάκι.",
    "Program_repair": "Αυτός μπορεί να είναι ο σωστός κώδικας, μπορείς να τον διορθώσεις;",
    "Program_too_long": "Το πρόγραμμά σου παίρνει πολύ χρόνο για να εκτελεστεί.",
    "ServerError": "Έγραψες ένα πρόγραμμα που δεν περιμέναμε. Αν θέλετε να βοηθήσεις, στείλε μας ένα email με το επίπεδο και το πρόγραμμά σου at hello@hedy.org. Εν τω μεταξύ, δοκίμασε κάτι λίγο διαφορετικό και ρίξε άλλη μια ματιά στα παραδείγματα. Ευχαριστώ!",
    "Transpile_error": "Δεν μπορούμε να εκτελέσουμε το πρόγραμμά σου.",
    "Transpile_success": "Καλή δουλειά!\nΕξαιρετικά!\nΜπράβο!\nΥπέροχα!\nΤα πήγες υπέροχα!",
    "Transpile_warning": "Προσοχή!",
    "Unsaved_Changes": "Έχεις ένα μη αποθηκευμένο πρόγραμμα. Θέλεις να αποχωρήσεις χωρίς να το αποθηκεύσεις;",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Αντίγραψε τον σύνδεσμο για κοινή χρήση",
    "customization_deleted": "Οι προσαρμογές διαγράφησαν με επιτυχία.",
    "dice": "🎲",
    "directly_available": "Άμεσα ανοιχτό",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Επίπεδο",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Καλώς ήρθες στη Hedy! Είσαι πλέον περήφανος κάτοχος ενός λογαριασμού καθηγητή που σου επιτρέπει να δημιουργείς μαθήματα και να προσκαλείς μαθητές.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "en": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "eo": {
    "CheckInternet": "Kontrolu, ĉu via Interreta konekto funkcias ĝuste.",
    "Connection_error": "Ni ne povis atingi la servilon.",
    "Empty_output": "Tiu kodo funkcias sed presas nenion. Enmetu presan komandon en vian kodon, aŭ uzu la testudon por vidi ion.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Io misfunkciis dum rulado de la programo.",
    "Other_error": "Ho! Eble ni iomete eraris.",
    "Program_repair": "Tio povus esti la ĝusta kodo; ĉu vi povas ripari ĝin?",
    "Program_too_long": "Ruli vian programon bezonas troon da tempo.",
    "ServerError": "Vi verkis programon, kiun ni ne atendis. Se vi volas helpi, sendu al ni retmesaĝon menciantan la nivelon kaj vian programon ĉe hello@hedy.org. Dume, provu ion alietan, kaj rerigardu la ekzemplojn. Dankon!",
    "Transpile_error": "Ni ne povas ruli vian programon.",
    "Transpile_success": "Bona laboro!\nMirinda!\nBone farita!\nBonega!\nVi bonege faris!",
    "Transpile_warning": "Atentu!",
    "Unsaved_Changes": "Via programo estas ne konservita. Ĉu vi volas foriri sen konservi ĝin?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Rekte malfermi",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nivelo",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "es": {
    "CheckInternet": "Comprueba si tu conexión a Internet funciona.",
    "Connection_error": "No se ha podido conectar con el servidor.",
    "Empty_output": "Este código funciona pero no imprime nada. Agrega un comando print a tu código o usa una tortuga para mostrar el resultado.",
    "Errors_found": "¡Has cometido un error! No te preocupes, seguimos ejecutando el programa",
    "Execute_error": "Algo salió mal.",
    "Other_error": "¡Ups! Tal vez cometimos un pequeño error.",
    "Program_repair": "Este podría ser código correcto, ¿lo puedes arreglar?",
    "Program_too_long": "Tu programa toma demasiado tiempo en ejecutarse.",
    "ServerError": "Puedes echarnos una mano enviando un correo electrónico con el nivel y tu programa a hello@hedy.org. Mientras tanto, prueba algo distinto. ¡Gracias!",
    "Transpile_error": "No podemos ejecutar este programa.",
    "Transpile_success": "¡Buen trabajo!\n¡Increible!\n¡Bien hecho!\n¡Excelente!\n¡Lo hiciste genial!",
    "Transpile_warning": "¡Cuidado!",
    "Unsaved_Changes": "Tienes un programa sin guardar. ¿Quieres salir sin guardarlo?",
    "adventures_completed": "Aventuras completadas: {number_of_adventures}",
    "adventures_restored": "Se han restaurado las aventuras por defecto.",
    "adventures_tried": "Aventuras intentadas",
    "copy_link_to_share": "Copiar enlace para compartir",
    "customization_deleted": "Personalización eliminada.",
    "dice": "🎲",
    "directly_available": "Directamente abierto",
    "disabled": "Deshabilitado",
    "errors": "Errores",
    "fortune": "🔮, ✨",
    "graph_title": "Errores por aventura completada en el nivel {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nivel",
    "multiple_keywords_warning": "Está intentando utilizar la palabra clave {orig_keyword}, pero esta palabra clave puede tener varios significados. Por favor, elija la que está intentando utilizar de esta lista y cópiela y péguela en su código, llaves incluidas: {keyword_list}",
    "number_of_errors": "Número de errores: {number_of_errors}",
    "one_level_error": "Debes seleccionar al menos un nivel.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Seleccionar todo",
    "selected": "Seleccionado",
    "songs": "🎵,🎶",
    "successful_runs": "Ejecuciones exitosas: {successful_runs}",
    "teacher_welcome": "¡Bienvenido/a a Hedy! Ahora eres el orgulloso dueño de una cuenta de profesor, lo que te permite crear clases e invitar a alumnos.",
    "turtle": "🐢",
    "unsaved_class_changes": "Hay cambios sin guardar, ¿estás seguro de que quieres abandonar esta página?"
  },
  "et": {
    "CheckInternet": "Kontrolli kas su internetiühendus töötab korralikult.",
    "Connection_error": "Me ei saanud serveriga ühendust.",
    "Empty_output": "See programm töötab, aga ei kirjuta midagi ekraanile. Lisa printimise käsk oma koodi või kasuta kilpkonna, et saada väljundit.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Midagi läks programmi käivitamisel valesti.",
    "Other_error": "Oih! Võib-olla me tegime programmis väikese vea.",
    "Program_repair": "See programm saaks olla õige, kas sa oskad seda parandada?",
    "Program_too_long": "Sinu programm võtab pärast käivitamist liiga kaua aega.",
    "ServerError": "Sa oled kirjutanud sellise programmi, mida me ei oodanud. Kui sa tahad meid aidata, siis saada meil aadressile hello@hedy.org. Kirjuta meili oma tase ja programm. Samal ajal proovi oma programmi natukene muuta, et sa saaksid edasi minna. Vaata uuesti näidiseid, kui on vaja. Suur tänu!",
    "Transpile_error": "Me ei saa sinu programmi käivitada.",
    "Transpile_success": "Tubli!\nVäga tubli!\nSuurepärane!\nSuper!\nVäga väga tubli!",
    "Transpile_warning": "Hoiatus!",
    "Unsaved_Changes": "Sinu programm ei ole salvestatud. Kas sa tahad lahkuda ilma seda salvestamata?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Tere tulemast Hedy juurde! Sul on nüüd õpetaja konto. See annab sulle võimaluse klasse luua ja õpilasi ühinema kutsuda.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "fa": {
    "CheckInternet": "ببین آیا اتصال اینترنت شما به درستی کار می کند.",
    "Connection_error": "نمیتونیم به سرور دسترسی داشته باشیم.",
    "Empty_output": "این کد کار می کند اما چیزی را چاپ نمی کند. یک دستور چاپ را به کد خود اضافه کن یا از لاک پشت برای خروجی استفاده کن.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "هنگام اجرای برنامه مشکلی پیش آمد.",
    "Other_error": "اوه! ممکنه ما یک اشتباه کوچکی کردیم.",
    "Program_repair": "این میتونه کد صحیح باشه، میتونی درستش کنی؟",
    "Program_too_long": "اجرای برنامه ات خیلی طول می کشه.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "نمیتونی برنامه ات را اجرا کنی.",
    "Transpile_success": "آفرین!\nشگفت انگيزه!\nآفرین!\nعالی!\nکارت عالی بود!",
    "Transpile_warning": "هشدار!",
    "Unsaved_Changes": "یک برنامه ذخیره نشده داری. آیا می خواهی بدون ذخیره آن را ترک کنی؟",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "fi": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "fr": {
    "CheckInternet": "Vérifie que ta connexion Internet fonctionne correctement.",
    "Connection_error": "Nous n’avons pas réussi à contacter le serveur.",
    "Empty_output": "Ce code fonctionne mais n'imprime rien. Ajoute une commande d'affichage à ton code ou utilise la tortue pour obtenir un résultat.",
    "Errors_found": "Tu as fait une erreur ! Ne t'inquiète pas, nous avons exécuté quand même le programme",
    "Execute_error": "Quelque chose s’est mal passé en exécutant ce programme.",
    "Other_error": "Oups ! Peut-être que nous faisons une petite erreur.",
    "Program_repair": "Ceci pourrait être le bon code, peux-tu le réparer ?",
    "Program_too_long": "Ton programme prend trop de temps à s'exécuter.",
    "ServerError": "Tu as écrit un programme que nous n'attendions pas. Si tu veux nous aider, envoie-nous un email avec le niveau et ton programme à hello@hedy.org. En attendant, essaye quelque chose d'un peu différent et regarde à nouveau les exemples. Merci !",
    "Transpile_error": "Nous ne pouvons pas exécuter ton programme.",
    "Transpile_success": "Bon travail !\nFantastique !\nBien joué !\nExcellent !\nTu as fait du bon travail !",
    "Transpile_warning": "Attention !",
    "Unsaved_Changes": "Le programme en cours n'a pas été sauvegardé. Souhaites-tu vraiment quitter sans le sauvegarder ?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Les aventures par défaut ont été rétablies.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copier le lien à partager",
    "customization_deleted": "Modifications effacées avec succès.",
    "dice": "🎲",
    "directly_available": "Ouvrir immédiatement",
    "disabled": "Désactivé",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Niveau",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Bienvenue chez Hedy ! Vous êtes maintenant l'heureux propriétaire d'un compte enseignant qui vous permet de créer des cours et d'inviter des étudiants.",
    "turtle": "🐢",
    "unsaved_class_changes": "Il y a des changements non pris en comptes, es-tu sur de vouloir quitter la page ?"
  },
  "fr_CA": {
    "CheckInternet": "Vérifie que ta connexion Internet fonctionne correctement.",
    "Connection_error": "Nous n’avons pas réussi à contacter le serveur.",
    "Empty_output": "Ce code fonctionne mais n'imprime rien. Ajoute une commande d'affichage à ton code ou utilise la tortue pour obtenir un résultat.",
    "Errors_found": "Tu as fait une erreur ! Ne t'inquiète pas, nous avons exécuté quand même le programme",
    "Execute_error": "Quelque chose s’est mal passé en exécutant ce programme.",
    "Other_error": "Oups ! Peut-être que nous faisons une petite erreur.",
    "Program_repair": "Ceci pourrait être le bon code, peux-tu le réparer ?",
    "Program_too_long": "Ton programme prend trop de temps à s'exécuter.",
    "ServerError": "Tu as écrit un programme que nous n'attendions pas. Si tu veux nous aider, envoie-nous un email avec le niveau et ton programme à hello@hedy.org. En attendant, essaye quelque chose d'un peu différent et regarde à nouveau les exemples. Merci !",
    "Transpile_error": "Nous ne pouvons pas exécuter ton programme.",
    "Transpile_success": "Bon travail !\nFantastique !\nBien joué !\nExcellent !\nTu as fait du bon travail !",
    "Transpile_warning": "Attention !",
    "Unsaved_Changes": "Le programme en cours n'a pas été sauvegardé. Souhaites-tu vraiment quitter sans le sauvegarder ?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Les aventures par défaut ont été rétablies.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copier le lien à partager",
    "customization_deleted": "Modifications effacées avec succès.",
    "dice": "🎲",
    "directly_available": "Ouvrir immédiatement",
    "disabled": "Désactivé",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Niveau",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Bienvenue chez Hedy ! Vous êtes maintenant l'heureux propriétaire d'un compte enseignant qui vous permet de créer des cours et d'inviter des étudiants.",
    "turtle": "🐢",
    "unsaved_class_changes": "Il y a des changements non pris en comptes, es-tu sur de vouloir quitter la page ?"
  },
  "fy": {
    "CheckInternet": "Wolst eefkes kontrolearje oft dyn internet it noch wol docht.",
    "Connection_error": "Der is in ferbiningsprobleem. It leit fêst oan ús, mar miskien docht dyn internet it eefkes net.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "It draaien fan de koade gong niet hielendal goed.",
    "Other_error": "Krammele! Dat gong net goed, wy ha sels fêst in programmearflater makke.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "Hast in programma skreaun dat wy net ferwachte hienen. Ast ús helpe wolst, stjoer ús dan in mailtsje mei in level en dyn programma nei hello@hedy.org. Besykje dyn programma in bytsje oan te passen om fierder te gean en sjochris nei de foarbylden. Dankewol!",
    "Transpile_error": "Dyn koade wie net hielendal goed.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Tink derom!",
    "Unsaved_Changes": "Dyn programma is net bewarre. Wolst fuortgean sûnder it te bewarjen?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Diellink kopiëare",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "he": {
    "CheckInternet": "בדקו אם חיבור האינטרנט שלכם.ן פועל כראוי.",
    "Connection_error": "לא הצלחנו להגיע לשרת.",
    "Empty_output": "הקוד הזה עובד אבל לא מדפיס שום דבר. הוסיפו פקודת הדפסה לקוד או השתמשו בצב בשביל לקבל פלט.",
    "Errors_found": "עשית טעות! אל דאגה, אנחנו עדיין נריץ את התוכנית",
    "Execute_error": "משהו השתבש במהלך הרצת התכנית.",
    "Other_error": "אופס! אולי עשינו טעות קטנה.",
    "Program_repair": "הקוד הזה יכול להיות נכון, האם תוכלו לתקן אותו?",
    "Program_too_long": "לתוכנית שלך לוקח יותר מדי זמן לרוץ.",
    "ServerError": "כתבת תוכנית שלא ציפינו לה. אם את.ה רוצה עזרה, שלח.י לנו מייל עם מספר הרמה והתוכנית שלך ל- hello@hedy.org. בינתיים, נסה.י משהו קצת שונה והסתכל.י על הדוגמאות. תודה!",
    "Transpile_error": "איננו יכולים.ות להריץ את התוכנית שלך.",
    "Transpile_success": "עבודה טובה!\nמדהים!\nכל הכבוד!\nמצוין!\nהיית מעולה!",
    "Transpile_warning": "אזהרה!",
    "Unsaved_Changes": "יש לך תכנית שלא נשמרה. האם ברצונך לעזוב בלי לשמור אותה?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "רמה",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "hi": {
    "CheckInternet": "जांचें कि आपका इंटरनेट कनेक्शन काम कर रहा है या नहीं।",
    "Connection_error": "हम सर्वर तक नहीं पहुंच सके|",
    "Empty_output": "यह कोड काम करता है लेकिन कुछ भी प्रिंट नहीं करता है। अपने कोड में एक प्रिंट कमांड जोड़ें या आउटपुट प्राप्त करने के लिए टर्टल का उपयोग करें|",
    "Errors_found": "आपने एक गलती कर दी! चिंता न करें, हमने अभी भी प्रोग्राम चलाया",
    "Execute_error": "प्रोग्राम चलाते समय कुछ गलत हो गया|",
    "Other_error": "उफ़! शायद हमने थोड़ी सी गलती कर दी।",
    "Program_repair": "यह सही कोड हो सकता है, क्या आप इसे ठीक कर सकते हैं?",
    "Program_too_long": "आपका प्रोग्राम चलने में बहुत अधिक समय लेता है।",
    "ServerError": "आपने एक ऐसा प्रोग्राम लिखा जिसकी हम अपेक्षा नहीं कर रहे थे। यदि आप मदद करना चाहते हैं, तो हमें hello@hedy.org पर स्तर और अपने प्रोग्राम के साथ एक ईमेल भेजें। इस बीच, कुछ अलग करने की कोशिश करें और उदाहरणों पर एक और नज़र डालें। धन्यवाद!",
    "Transpile_error": "हम आपका प्रोग्राम नहीं चला सकते।",
    "Transpile_success": "अच्छा कार्य!\nअद्भुत!\nबहुत बढ़िया!\nउत्कृष्ट!\nआपने बहुत अच्छा किया!",
    "Transpile_warning": "चेतावनी!",
    "Unsaved_Changes": "आपके पास एक सहेजा नहीं गया प्रोग्राम है। क्या आप इसे सहेजे बिना छोड़ना चाहते हैं?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "शेयर करने के लिए लिंक कॉपी करें",
    "customization_deleted": "अनुकूलन सफलतापूर्वक हटा दिए गए।",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "स्तर",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "हेडी में आपका स्वागत है! अब आप एक शिक्षक खाते के गर्वित स्वामी हैं जो आपको कक्षाएं बनाने और छात्रों को आमंत्रित करने की अनुमति देता है।",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "hr": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "hu": {
    "CheckInternet": "Ellenőrizd, hogy az internetkapcsolat megfelelően működik-e.",
    "Connection_error": "Nem tudjuk elérni a szervert.",
    "Empty_output": "Működik a program, de nem ír ki semmit. Helyezz el egy kiírást a kódban, vagy használd a teknőst, hogy kimenetet kapj!",
    "Errors_found": "Hibát követtél el! Ne aggódjon, még mindig futtatjuk a programot",
    "Execute_error": "Hiba történt a program futtatása közben.",
    "Other_error": "Hoppá! Talán elkövettünk egy kis hibát.",
    "Program_repair": "Ez lehet a helyes kód, meg tudod javítani?",
    "Program_too_long": "Túl hosszú ideig tart programod futtatása.",
    "ServerError": "Olyan programot írtál, amire nem számítottunk. Ha segíteni szeretnél, küldj nekünk egy emailt a szinttel és a programmal a hello@hedy.org címre! Időközben próbáld ki egy kicsit másképp, és nézd meg a példákat! Köszönjük!",
    "Transpile_error": "Nem tudjuk futtatni a kódodat.",
    "Transpile_success": "Szép munka!\nLenyűgöző!\nÜgyes!\nKitűnő!\nRemekül csináltad!",
    "Transpile_warning": "Vigyázat!",
    "Unsaved_Changes": "Nem mentett programod van. El akarsz menni mentés nélkül?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Link másolása a megosztáshoz",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Szint",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "🗻, 📜, ✂️",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "Biztosan elhagyod ezt az oldalt? Mentetlen változtatásaid vannak, amik így elveszhetnek."
  },
  "ia": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "iba": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "id": {
    "CheckInternet": "Cek apakah internet anda bekerja dengan baik.",
    "Connection_error": "Kami tidak bisa terhubung dengan server.",
    "Empty_output": "Kode ini berfungsi tetapi tidak mencetak apa pun. Tambahkan print command ke dalam kode Anda atau gunakan turtle untuk mendapatkan hasil keluaran.",
    "Errors_found": "Kamu membuat kesalahan! Tidak perlu khawatir, program tetap berjalan",
    "Execute_error": "Sesuatu berjalan tidak seharusnya ketika program sedang dijalankan.",
    "Other_error": "Ups! Mungkin kamu membuat kesalahan kecil.",
    "Program_repair": "Apakah ini bisa menjadi kode yang benar, bisakah kamu memperbaikinya?",
    "Program_too_long": "Program Anda memakan waktu terlalu lama untuk berjalan.",
    "ServerError": "Kamu menuliskan sebuah program yang tidak kami duga. Jika kamu butuh bantuan, kirimkan email dengan informasi level dan program kamu ke hello@hedy.org. Sembari menunggu respon, cobalah cara lain atau lihat kembali contoh-contoh yang disediakan. Terima kasih!",
    "Transpile_error": "Kami tidak bisa menjalankan kode kamu.",
    "Transpile_success": "Kerja bagus!\nKeren!\nBagus sekali!\nBagus sekali!\nKamu sudah bagus!",
    "Transpile_warning": "Peringatan!",
    "Unsaved_Changes": "Kamu memiliki program yang belum disimpan. Apakah kamu mau keluar tanpa menyimpannya?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Petualangan default telah dipulihkan.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Salin tautan untuk berbagi",
    "customization_deleted": "Penyesuaian berhasil dihapus.",
    "dice": "🎲",
    "directly_available": "Terbuka langsung",
    "disabled": "Dinonaktifkan",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Selamat datang di Hedy! Anda sekarang bangga menjadi pemilik akun guru yang memungkinkan Anda membuat kelas dan mengundang siswa.",
    "turtle": "🐢",
    "unsaved_class_changes": "Ada perubahan yang belum disimpan, yakin ingin meninggalkan halaman ini?"
  },
  "it": {
    "CheckInternet": "Dai un'occhiata se la tua connessione Internet funziona correttamente.",
    "Connection_error": "Non siamo riuscito a contattare il server.",
    "Empty_output": "Questo codice funziona ma non stampa nulla. Aggiungi un comando print al tuo codice o usa la tartaruga per ottenere l'output.",
    "Errors_found": "Hai fatto un errore! Non preoccuparti, abbiamo comunque eseguito il programma",
    "Execute_error": "Qualcosa è andato storto nell'esecuzione del tuo codice.",
    "Other_error": "Ops! Forse abbiamo fatto un errore.",
    "Program_repair": "Questo potrebbe essere il codice corretto, puoi risolverlo?",
    "Program_too_long": "Il tuo programma impiega troppo tempo per essere eseguito.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "Non possiamo eseguire il tuo programma.",
    "Transpile_success": "Ottimo lavoro!\nFantastico!\nBen fatto!\nEccellente!\nSei stato bravissimo!",
    "Transpile_warning": "Attenzione!",
    "Unsaved_Changes": "Hai un programma non salvato. Vuoi uscire senza salvare?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "Dado",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "fortuna",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "infestato",
    "level_title": "Livello",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "Ristorante",
    "rock": "Roccia",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "Suono",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "Tartaruga",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ja": {
    "CheckInternet": "インターネットの接続を調べてください。",
    "Connection_error": "サーバに接続できません。",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "あ！多分、私たちは間違いしました。ごめんなさい。",
    "Program_repair": "このコードは正解ができます。直せますか？",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "プログラムを実行ができません。",
    "Transpile_success": "よくできた！\nすごい！\nよくやった！\nえらい！\n頑張ったね！",
    "Transpile_warning": "警告！",
    "Unsaved_Changes": "非保存プログラムがあります。それでも、終了しますか？",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "kab": {
    "CheckInternet": "Senqed tuqqna-k ɣer Internet tetteddu akken iwata.",
    "Connection_error": "Ur nessaweḍ ara ɣer uqeddac.",
    "Empty_output": "Tangalt-a tetteddu maca ur d-tessigiz acemma. Rnu taladna n tasaggazt i tengalt-ik neɣ seqdec ifker akken ad tafeḍ kra n ugmuḍ.",
    "Errors_found": "Tgiḍ tuccḍa ! Ur ttagad, nselkem ahil",
    "Execute_error": "Yella wayen ur neddi ara.",
    "Other_error": "Ihuh! Waqil nga tuccḍa tamecṭuḥt.",
    "Program_repair": "Yezmer wagi d tangalt tameɣtut, tzemreḍ ad t-tṣeggmeḍ ?",
    "Program_too_long": "Ahil-ik yettaṭṭaf aṭas n wakud i wakken ad yekker.",
    "ServerError": "Tzemreḍ ad aɣ-tɛawneḍ s tuzna n yimayl s uswir akked wahil-ik ɣer hello@hedy.org. Sya ar wass-nni, εreḍ kra yemgaraden. Tanemmirt!",
    "Transpile_error": "Ur nezmir ara ad nseddu ahil-ik.",
    "Transpile_success": "Igerrez!\nGedha!\nYelha!\nIzad!\nD amahil izaden!",
    "Transpile_warning": "Ɣur-k!",
    "Unsaved_Changes": "Ahil-a ur yettwasekles ara. Tebɣiḍ ad teffɣeḍ war ma teskelseḍ-t?",
    "adventures_completed": "Tidyanin mmdent: {number_of_adventures}",
    "adventures_restored": "Tidyanin timezwer tettwarr-d.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Nɣel aseɣwen ara tebḍuḍ",
    "customization_deleted": "Isenfal ttwasefḍen akken iwata.",
    "dice": "🎲",
    "directly_available": "Ldi imir",
    "disabled": "Yensa",
    "errors": "Tuccḍiwin",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Aswir",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Amḍan n tuccḍiwin: {number_of_errors}",
    "one_level_error": "Tesriḍ ad tferneḍ xerṣum yiwen uswir.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Fren kullec",
    "selected": "Yettwafren",
    "songs": "🎵,🎶",
    "successful_runs": "Iteddu akken iwata: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "kmr": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ko": {
    "CheckInternet": "인터넷 연결이 제대로 작동하는지 확인해 보세요.",
    "Connection_error": "서버에 연결이 안 됐어요.",
    "Empty_output": "이 코드는 작동하지만 아무것도 인쇄하지 않습니다. 코드에 인쇄 명령을 추가하거나 거북이를 사용하여 출력을 얻으십시오.",
    "Errors_found": "틀린것 같아요! 걱정 마세요, hedy는 실수를 찾으려 하고 있어요",
    "Execute_error": "프로그램을 실행하는 동안 문제가 생겼어요.",
    "Other_error": "이런! 저희가 작은 실수를 했나봐요.",
    "Program_repair": "이게 맞는 코드일 수도 있는데 수정 해주실 수 있나요?",
    "Program_too_long": "프로그램을 실행하는 데 시간이 너무 오래 걸리네요.",
    "ServerError": "당신은 저희가 예상하지 못했던 프로그램을 작성했습니다. 만약 도움을 주고 싶다면, 우리에게 레벨과 hello@hedy.org 에 있는 당신의 프로그램과 함께 이메일을 보내주세요. 그 동안, 조금 다른 것을 시도해보고 사례를 다시 살펴보세요. 감사합니다!",
    "Transpile_error": "프로그램을 실행할 수 없습니다.",
    "Transpile_success": "수고하셨어요!\n최고에요!\n정말 잘하셨어요!\n훌륭해요!\n대단해요!",
    "Transpile_warning": "경고!",
    "Unsaved_Changes": "저장하지 않은 프로그램이 있습니다. 저장하지 않고 나가시겠습니까?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "기본 모험이 복구되었습니다.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "공유할 링크 복사",
    "customization_deleted": "사용자 지정을 성공적으로 삭제했습니다.",
    "dice": "🎲",
    "directly_available": "직접 열림",
    "disabled": "비활성화됨",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "레벨",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "mi": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ms": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ne": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "nl": {
    "CheckInternet": "Controleer of jouw internetverbinding wel goed werkt.",
    "Connection_error": "We konden niet goed met de server praten.",
    "Empty_output": "Deze code werkt wel, maar print niks. Voeg een print commando toe aan je code of gebruik de schildpad om output te krijgen.",
    "Errors_found": "Je hebt een foutje gemaakt! Maak je geen zorgen, Hedy probeert de fouten te vinden",
    "Execute_error": "Er ging iets fout bij het uitvoeren van het programma.",
    "Other_error": "Oeps! Misschien hebben wij een klein programmeerfoutje gemaakt.",
    "Program_repair": "Dit kan de correcte code zijn, kan je jouw code repareren?",
    "Program_too_long": "Jouw programma duurt te lang bij het uitvoeren.",
    "ServerError": "Je hebt een programma geschreven dat we niet verwacht hadden. Als je wilt helpen, stuur ons dan een mailtje met het level en je programma op hello@hedy.org. Probeer om verder te gaan je programma een beetje aan te passen en kijk nog eens goed naar de voorbeelden. Bedankt!",
    "Transpile_error": "We konden je code niet goed lezen.",
    "Transpile_success": "Goed gedaan!\nGa zo door!\nTopper!\nSuper!\nBravo!",
    "Transpile_warning": "Let op!",
    "Unsaved_Changes": "Jouw programma is niet opgeslagen. Wil je weggaan zonder het op te slaan?",
    "adventures_completed": "Avonturen afgerond: {number_of_adventures}",
    "adventures_restored": "De standaardavonturen zijn terug gezet.",
    "adventures_tried": "Avonturen geprobeerd",
    "copy_link_to_share": "Kopieer link voor delen",
    "customization_deleted": "Personalisatie succesvol verwijderd.",
    "dice": "🎲",
    "directly_available": "Gelijk open",
    "disabled": "Gedeactiveerd",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Fouten per avontuur in level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Selecteer alles",
    "selected": "Geselecteerd",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welkom bij Hedy! Jouw account is omgezet naar een lerarenaccount. Je kan nu klassen maken en er leerlingen in uitnodigen.",
    "turtle": "🐢",
    "unsaved_class_changes": "Er zijn wijzigingen nog niet opgeslagen, weet je zeker dat je terug wilt gaan?"
  },
  "no_NB": {
    "CheckInternet": "Sjekk internetttilkoblingen din, fungerer den.",
    "Connection_error": "Vi fikk ikke kontakt med serveren.",
    "Empty_output": "Denne koden virker men den skriver ikke ut noe. Legg til en print kommando til koden din eller bruk skipadden til å få noe output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Noe gikk feil mens vi kjørte programmet.",
    "Other_error": "Oops! Kanskje vi gjorde en liten feil.",
    "Program_repair": "Dette kan være det korrekte programmet, kan du fikse det?",
    "Program_too_long": "Programmet ditt tok for lang tid å kjøre.",
    "ServerError": "Du skrev et program vi ikke forventet oss. Om du trenger hjelp, send en e-post med nivået du er på og programmet til hello@hedy.org. I mellomtiden, prøv noe annet og ta en titt på eksemplene. Takk!",
    "Transpile_error": "Vi kan ikke kjøre programmet ditt.",
    "Transpile_success": "Godt jobbet!\nFantastisk!\nVeldig bra!\nSupert!\nDette fikk du til!",
    "Transpile_warning": "Advarsel!",
    "Unsaved_Changes": "Du har ulagrede endringer i programmet. Vil du gå uten å lagre de?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "pa_PK": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "pap": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "peo": {
    "CheckInternet": "ببین آیا اتصال اینترنت شما به درستی کار می کند.",
    "Connection_error": "نمیتونیم به سرور دسترسی داشته باشیم.",
    "Empty_output": "این کد کار می کند اما چیزی را چاپ نمی کند. یک دستور چاپ را به کد خود اضافه کن یا از لاک پشت برای خروجی استفاده کن.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "هنگام اجرای برنامه مشکلی پیش آمد.",
    "Other_error": "اوه! ممکنه ما یک اشتباه کوچکی کردیم.",
    "Program_repair": "این میتونه کد صحیح باشه، میتونی درستش کنی؟",
    "Program_too_long": "اجرای برنامه ات خیلی طول می کشه.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "نمیتونی برنامه ات را اجرا کنی.",
    "Transpile_success": "آفرین!\nشگفت انگيزه!\nآفرین!\nعالی!\nکارت عالی بود!",
    "Transpile_warning": "هشدار!",
    "Unsaved_Changes": "یک برنامه ذخیره نشده داری. آیا می خواهی بدون ذخیره آن را ترک کنی؟",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "pl": {
    "CheckInternet": "Upewnij się, że masz połączenie z Internetem.",
    "Connection_error": "Błąd połączenia z serwerem.",
    "Empty_output": "Ten kod działa, ale niczego nie wypisuje. Dodaj komendę 'napisz' lub użyj żółwia, by otrzymać dane wyjściowe.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Coś poszło nie tak w trakcie wykonywania programu.",
    "Other_error": "Ups! Może popełniliśmy mały błąd.",
    "Program_repair": "To może być poprawny kod, czy możesz go naprawić?",
    "Program_too_long": "Twój program działa zbyt długo.",
    "ServerError": "Napisałeś program, jakiego się nie spodziewaliśmy. Jeżeli chcesz nam pomóc, wyślij wiadomość na adres e-mail hello@hedy.org i wskaż poziom, na którym napisałeś ten program oraz załącz jego kod. W międzyczasie spróbuj czegoś innego i ponownie spójrz na przykłady. Dzięki!",
    "Transpile_error": "Program nie może zostać uruchomiony.",
    "Transpile_success": "Dobra robota!\nWspaniale!\nŚwietnie!\nDoskonale!\nDobrze Ci poszło!",
    "Transpile_warning": "Ostrzeżenie!",
    "Unsaved_Changes": "Twój program nie został zapisany. Chcesz wyjść mimo to?",
    "adventures_completed": "Ukończone przygody: {number_of_adventures}",
    "adventures_restored": "Domyślne przygody zostały przywrócone.",
    "adventures_tried": "Wypróbowane przygody",
    "copy_link_to_share": "Skopiuj udostępniający link",
    "customization_deleted": "Personalizacje zostaly usunięte pomyślnie.",
    "dice": "🎲",
    "directly_available": "Otwórz bezpośrednio",
    "disabled": "Wyłączono",
    "errors": "Błędy",
    "fortune": "🔮, ✨",
    "graph_title": "Błędy na ukończone przygody na poziomie {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Poziom",
    "multiple_keywords_warning": "Próbujesz użyć słowa kluczowego{orig_keyword}, ale to słowo kluczowe może mieć kilka znaczeń. Wybierz to, którego próbujesz użyć z tej listy i skopiuj a następnie wklej to słowo do kodu, w nawiasach klamrowych: {keyword_list}",
    "number_of_errors": "Liczba błędów: {number_of_errors}",
    "one_level_error": "Musisz wybrać co najmniej jeden poziom.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Wybierz wszystko",
    "selected": "Wybrano",
    "songs": "🎵,🎶",
    "successful_runs": "Udane uruchomienia: {successful_runs}",
    "teacher_welcome": "Witaj w Hedy! Jesteś teraz dumnym posiadaczem konta nauczyciela, które pozwala Ci na tworzenie klas i zapraszanie uczniów.",
    "turtle": "🐢",
    "unsaved_class_changes": "Strona zawiera niezapisane zmiany. Czy jesteś pewien, że chcesz wyjść bez zapisania zmian?"
  },
  "pt_BR": {
    "CheckInternet": "Verifique se sua conexão com a Internet está funcionando.",
    "Connection_error": "Nós não conseguimos conectar ao servidor.",
    "Empty_output": "Este código funciona mas não imprime nada. Adicione um comando de impressão ou use a tartaruga para ter alguma saída.",
    "Errors_found": "Você cometeu um erro! Não se preocupe, ainda executamos o programa",
    "Execute_error": "Alguma coisa deu errado enquanto o programa era executado.",
    "Other_error": "Opa! Talvez tenhamos feito alguma coisa errada.",
    "Program_repair": "Este poderia ser o código correto, você poderia corrigir ele?",
    "Program_too_long": "O seu programa leva muito tempo para executar.",
    "ServerError": "Você escreveu um programa que não estávamos esperando. Se você quiser ajudar, envie-nos um email com o nível e seu programa para hello@hedy.org. Enquanto isso, tente algo um pouco diferente e dê uma outra olhada nos exemplos. Obrigado!",
    "Transpile_error": "Não conseguimos executar o seu programa.",
    "Transpile_success": "Bom trabalho!\nImpressionante!\nMuito bem!\nExcelente!\nÓtimo!",
    "Transpile_warning": "Aviso!",
    "Unsaved_Changes": "Você tem um programa não salvo. Você deseja sair sem salvá-lo?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "As aventuras padrão foram restauradas.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copiar link para compartilhar",
    "customization_deleted": "Personalizações deletadas com sucesso.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Desativado",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nível",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Selecionar tudo",
    "selected": "Selecionado",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "Há alterações não salvas, tem certeza de que quer sair desta página?"
  },
  "pt_PT": {
    "CheckInternet": "Verifique se a sua ligação à Internet está a funcionar.",
    "Connection_error": "Não conseguimos contactar o servidor.",
    "Empty_output": "Este código funciona mas não imprime nada. Acrescenta um comando de impressão ao teu código ou usa a tartaruga para obteres resultados.",
    "Errors_found": "Cometeram um erro! Não se preocupem, ainda corremos o programa",
    "Execute_error": "Alguma coisa correu mal enquanto executávamos o programa.",
    "Other_error": "Oops! Talvez cometemos um pequeno erro.",
    "Program_repair": "Este pode ser o código correto, podes corrigi-lo?",
    "Program_too_long": "O teu programa demora demasiado tempo a ser executado.",
    "ServerError": "Escreveste um programa que não estávamos à espera. Se queres ajudar, envia-nos um email com o nível e o teu programa para hello@hedy.org. Entretanto, experimenta alguma coisa um pouco diferente e volta a olhar para os exemplos. Obrigado!",
    "Transpile_error": "Não conseguimos executar o teu programa.",
    "Transpile_success": "Bom trabalho!\nFantástico!\nMuito bem!\nExcelente!\nEstiveste muito bem!",
    "Transpile_warning": "Aviso!",
    "Unsaved_Changes": "Tens um programa por guardar. Queres sair sem o guardar?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "As aventuras padrão foram restauradas.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copiar ligação de partilha",
    "customization_deleted": "Personalizações deletadas com sucesso.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Desativado",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nível",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "tartaruga🐢",
    "unsaved_class_changes": "Há alterações não gravadas, tem certeza que quer sair desta página?"
  },
  "ro": {
    "CheckInternet": "Verifică dacă ai semnal la internet.",
    "Connection_error": "Nu am putut contacta serverul.",
    "Empty_output": "Acest program funcționează dar nu printează nimic. Adaugă o comandă de printare sau folosește țestoasa să obții un rezultat.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Ceva nu a funcționat corect in timpul rulării programului.",
    "Other_error": "Opa! Este posibil să fi făcut vreo greșeală.",
    "Program_repair": "Probabil așa arată programul corect, poți corecta?",
    "Program_too_long": "Rularea programului tău durează prea mult.",
    "ServerError": "Ai scris un program neașteptat. Dacă ai nevoie de ajutor trimite-ne un email la: hello@hedy.org și specifică nivelul. Intre timp incearcă ceva diferit și incearcă din nou exemplele. Mulțumim!",
    "Transpile_error": "Nu putem rula program tău.",
    "Transpile_success": "Excelent!\nExtraordinar!\nFoarte bine!\nExcelent!\nFoarte bine!",
    "Transpile_warning": "Atenție!",
    "Unsaved_Changes": "Ai un program care nu este salvat. Dorești să ieși fără salvare?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ru": {
    "CheckInternet": "Проверь своё интернет подключение.",
    "Connection_error": "Не могу найти сервер.",
    "Empty_output": "Этот код работает, но не выводит ничего. Добавь команду печать в твой код или используй черепашке чтобы получить вывод.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Что-то пошло не так при запуске программы.",
    "Other_error": "Упс, кажется вышла ошибочка.",
    "Program_repair": "Это может быть правильный код, можете ли вы его исправить?",
    "Program_too_long": "Ваша программа выполняется слишком долго.",
    "ServerError": "Вы написали программу, которую мы не ожидали. Если вы хотите помочь, пришлите нам письмо с указанием уровня и вашей программы по адресу hello@hedy.org. А пока попробуйте сделать что-то другое и посмотрите еще раз на примеры. Спасибо!",
    "Transpile_error": "Мы не можем запустить твою программу.",
    "Transpile_success": "Отличная работа!\nПотрясающе!\nОтлично получилось!\nПрекрасно!\nУ тебя отлично получается!",
    "Transpile_warning": "Внимание!",
    "Unsaved_Changes": "У тебя не сохранена программа. Хочешь выйти без сохранения?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Скопируйте ссылку, чтобы поделиться",
    "customization_deleted": "Настройки успешно удалены.",
    "dice": "🎲",
    "directly_available": "Прямое открытие",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Уровень",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Добро пожаловать в Hedy! Теперь вы являетесь гордым владельцем учетной записи учителя, которая позволяет вам создавать классы и приглашать учеников.",
    "turtle": "🐢",
    "unsaved_class_changes": "Есть несохраненные изменения, вы уверены, что хотите покинуть эту страницу?"
  },
  "sl": {
    "CheckInternet": "Preverite, če vaša spletna povezava deluje.",
    "Connection_error": "Strežnika nismo mogli priklicati.",
    "Empty_output": "Ta koda deluje, vendar ne izpiše ničesar. V kodo dodaj ukaz natisni ali pa uporabi želvo za izpis.",
    "Errors_found": "Naredili ste napako! Ne skrbite, program smo vseeno uspeli zagnati",
    "Execute_error": "Nekaj je šlo narobe med poganjanjem programa.",
    "Other_error": "Opala... Morda smo naredili malo napako.",
    "Program_repair": "To bi lahko bila pravilna koda, jo lahko popraviš?",
    "Program_too_long": "Tvoj program se izvaja predolgo.",
    "ServerError": "Napisali ste program, ki ga nismo pričakovali. Če želite pomagati, nam pošljite e-poštno sporočilo s stopnjo in svojim programom na naslov hello@hedy.org. Medtem pa poskusite nekaj malce drugačnega in si še enkrat oglejte primere. Hvala!",
    "Transpile_error": "Vašega programa ne moremo zagnati.",
    "Transpile_success": "Dobro opravljeno delo!\nNeverjetno!\nOdlično opravljeno delo!\nIzjemno!\nOdlično ste se odrezali!",
    "Transpile_warning": "Opozorilo!",
    "Unsaved_Changes": "Imate neshranjen program. Ali ga želite zapustiti, ne da bi ga shranili?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Stopnja",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "sq": {
    "CheckInternet": "Shikoni nëse lidhja juaj e internetit po funksionon siç duhet.",
    "Connection_error": "Nuk arritëm dot te serveri.",
    "Empty_output": "Ky kod funksionon, por nuk printon asgjë. Shtoni komandën print në kodin tuaj ose përdorni breshkën për të marrë rezultatin.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Diçka shkoi keq gjatë ekzekutimit të programit.",
    "Other_error": "Oops! Ndoshta kemi bërë një gabim të vogël.",
    "Program_repair": "Ky mund të jetë kodi i duhur, a mund ta rregulloni?",
    "Program_too_long": "Programi juaj merr shumë kohë për të ekzekutuar.",
    "ServerError": "Ke shkruar një program që nuk e prisnim. Nëse dëshironi të ndihmoni, na dërgoni një email me nivelin dhe programin tuaj në hello@hedy.org. Ndërkohë, provoni diçka pak më ndryshe dhe hidhini një sy shembujve. Faleminderit!",
    "Transpile_error": "Ne nuk mund ta ekzekutojmë programin tuaj.",
    "Transpile_success": "Punë e mirë!\nE mahnitshme!\nTe lumte!\nE shkëlqyeshme!\nJu dolët shumë mirë!",
    "Transpile_warning": "Paralajmërim!",
    "Unsaved_Changes": "Ju keni një program të paruajtur. Dëshiron të largohesh pa e ruajtur?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "sr": {
    "CheckInternet": "Проверите да ли ваша интернет веза ради.",
    "Connection_error": "Нисмо могли да приступимо серверу.",
    "Empty_output": "Овај код ради, али не приказује ништа. Додајте команду за приказивање у ваш код или користите корњачу за добијање излаза.",
    "Errors_found": "Направили сте грешку! Не брините, ипак смо покренули програм",
    "Execute_error": "Нешто је пошло како не треба.",
    "Other_error": "Упс! Можда смо направили малу грешку.",
    "Program_repair": "Ово би могао бити исправан код, можете ли га поправити?",
    "Program_too_long": "Ваш програм траје предуго да се изврши.",
    "ServerError": "Можете нам помоћи тако што ћете послати имејл са нивоом и вашим програмом на hello@hedy.org. У међувремену, покушајте нешто мало другачије. Хвала!",
    "Transpile_error": "Не можемо да покренемо ваш програм.",
    "Transpile_success": "Добар посао!\nНевероватно!\nБраво!\nОдлично!\nСјајно си урадио!",
    "Transpile_warning": "Упозорење!",
    "Unsaved_Changes": "Имате несачуван програм. Да ли желите да изађете без чувања?",
    "adventures_completed": "Завршене авантуре: {number_of_adventures}",
    "adventures_restored": "Подразумеване авантуре су враћене.",
    "adventures_tried": "Покушане авантуре",
    "copy_link_to_share": "Копирај везу за дељење",
    "customization_deleted": "Прилагођавања успешно обрисана.",
    "dice": "🎲",
    "directly_available": "Директно отвори",
    "disabled": "Онемогућено",
    "errors": "Грешке",
    "fortune": "🔮, ✨",
    "graph_title": "Грешке по завршеној авантури на нивоу {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Ниво",
    "multiple_keywords_warning": "Покушавате да користите кључну реч {orig_keyword}, али ова кључна реч може имати неколико значења. Молимо вас да изаберете ону коју покушавате да користите са ове листе и копирате је у свој код, укључујући коврџаве заграде: {keyword_list}",
    "number_of_errors": "Број грешака: {number_of_errors}",
    "one_level_error": "Морате изабрати бар један ниво.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Изабери све",
    "selected": "Изабрано",
    "songs": "🎵,🎶",
    "successful_runs": "Успешна извршења: {successful_runs}",
    "teacher_welcome": "Добродошли у Hedy! Сада сте поносни власник налога за наставнике који вам омогућава да креирате одељења и позивате ученике.",
    "turtle": "🐢",
    "unsaved_class_changes": "Постоје несачуване промене, да ли сте сигурни да желите да напустите ову страницу?"
  },
  "sv": {
    "CheckInternet": "Kontrollera att din internetanslutning fungerar.",
    "Connection_error": "Vi kunde inte nå servern.",
    "Empty_output": "Den här koden fungerar men skriver inget. Lägg till ett utskriftskommando till din kod eller använd sköldpaddan för att få något resultat.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Något gick fel när programmet kördes.",
    "Other_error": "Hoppsan! Vi gjorde nog ett litet misstag.",
    "Program_repair": "Detta kan vara rätt kod; kan du laga den?",
    "Program_too_long": "Ditt program tar för lång tid att köra.",
    "ServerError": "Du skrev ett program som vi inte väntade oss. Om du vill hjälpa oss, skicka ett mejl med nivån och ditt program till hello@hedy.org. Under tiden kan du testa att ändra lite och ta en ny titt på exemplen. Tack!",
    "Transpile_error": "Vi kan inte köra ditt program.",
    "Transpile_success": "Bra jobbat!\nFantastiskt!\nBra jobbat!\nUtmärkt!\nBra jobbat!",
    "Transpile_warning": "Varning!",
    "Unsaved_Changes": "Ditt program är inte sparat. Vill du lämna utan att spara det?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Standardäventyren har återställts.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Kopiera länken för att dela den",
    "customization_deleted": "Anpassningarna har tagits bort.",
    "dice": "🎲",
    "directly_available": "Öppna direkt",
    "disabled": "Inaktiverad",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Nivå",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Välkommen till Hedy! Du är nu den stolta ägaren av ett lärarkonto som ger dig möjlighet att skapa klasser och bjuda in elever.",
    "turtle": "🐢",
    "unsaved_class_changes": "Det finns osparade ändringar; är du säker på att du vill lämna sidan?"
  },
  "sw": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "Hatukuweza kufikia seva.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Matatizo fulani ilitokea wakati wa kuendesha programu.",
    "Other_error": "Oops! Labda tulifanya makosa kidogo.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "Hatuwezi kuendesha programu yako.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Onyo!",
    "Unsaved_Changes": "Una programu ambao haujahifadhiwa. Je! Unataka kuondoka bila kuiokoa?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Kiwango",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "ta": {
    "CheckInternet": "உங்கள் இணைய இணைப்பு செயல்படுகிறதா என சரிபார்க்கவும்.",
    "Connection_error": "எங்களால் சேவையகத்தை அடைய முடியவில்லை.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "உங்கள் நிரலில் பிழைகள் இருக்கின்றன! எனினும் கவலையுற வேண்டாம். இருப்பினும் உங்கள் நிரலை இயக்க முடிந்தது",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "te": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "th": {
    "CheckInternet": "เอ๋ อินเตอร์เน็ตมีปัญหาหรือเปล่า",
    "Connection_error": "เราติดต่อกับเซิฟเวอร์ไม่ได้.",
    "Empty_output": "โค้ดนี้รันได้ปกตินะ แต่ว่าไม่ได้พิมพ์อะไรออกมาเลย น่าจะลืมใช้คำสั่งแสดงหรือใช้ให้เต่าวาดรูปหรือเปล่า",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "เกิดข้อผิดพลาดระหว่างรันโปรแกรม",
    "Other_error": "อุ้ยย! น่าจะผิดพลาดอะไรเล็กน้อยหรือเปล่า",
    "Program_repair": "โค้ดนี้อาจจะถูกแล้ว แต่คุณลองแก้หน่อยได้มั้ย?",
    "Program_too_long": "โปรแกรมนี้ใช้เวลารันนานเกินไปนะ",
    "ServerError": "คุณเขียนโปรแกรมที่เราไม่คิดว่าจะมีคนเขียนมา เราจะขอบคุณมากถ้าคุณช่วยส่งโปรแกรมและเลเวลที่คุณอยู่มาทางอีเมล hello@hedy.org และในระหว่างนี้ ลองดูตัวอย่าง หรือลองเขียนด้วยวิธีอื่นดูก่อนนะ ขอบคุณมาก!",
    "Transpile_error": "โปรแกรมนี้ไม่สามารถรันได้",
    "Transpile_success": "เก่งมาก!\nสุดยอด!\nยอดเยี่ยม!\nเลิศมาก!\nทำได้ดีมาก!",
    "Transpile_warning": "คำเตือน!",
    "Unsaved_Changes": "โปรแกรมที่เขียนยังไม่ได้เซฟนะ จะออกจากหน้านี้เลย โดยไม่เซฟก่อนหรอ?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "tl": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "tn": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "tr": {
    "CheckInternet": "İnternet bağlantınızın çalışıp çalışmadığını kontrol edin.",
    "Connection_error": "Sunucuya ulaşamadık.",
    "Empty_output": "Bu kod çalışıyor ama hiçbir şey yazdırmıyor. Kodunuzda bir yazdırma komutu kullanın veya bir çıktı almak için kaplumbağayı kullanın.",
    "Errors_found": "Bir hata yaptınız! Endişelenmeyin, programı yine de çalıştırdık",
    "Execute_error": "Programı çalıştırırken bir şeyler ters gitti.",
    "Other_error": "Oops! Belki küçük bir hata yapmış olabiliriz.",
    "Program_repair": "Bu doğru kod olabilirdi, onu düzeltebilir misin?",
    "Program_too_long": "Programınızın çalışması çok uzun sürüyor.",
    "ServerError": "Bizim beklemediğimiz bir program yazdınız. Eğer yardım etmek isterseniz, bize hello@hedy.org adresinden ilgili seviye ve programınızla birlikte bir e-posta gönderin. Bu arada, biraz farklı bir şey deneyin ve örneklere bir kez daha göz atın. Teşekkürler!",
    "Transpile_error": "Programını çalıştıramıyoruz.",
    "Transpile_success": "Aferin!\nİnanılmaz!\nİyi işti, Aferin!\nMükemmel!\nÇok iyiydin!",
    "Transpile_warning": "Uyarı!",
    "Unsaved_Changes": "Kaydedilmemiş bir programın var. Kaydetmeden ayrılmak mı istiyorsun?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "Varsayılan maceralar geri yüklendi.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Paylaşmak için bağlantıyı kopyalayın",
    "customization_deleted": "Özelleştirmeler başarıyla silindi.",
    "dice": "🎲",
    "directly_available": "Doğrudan açın",
    "disabled": "Devre dışı",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Seviye",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Hedy'ye hoş geldiniz! Artık sınıflar oluşturmanıza ve öğrencileri davet etmenize olanak tanıyan bir öğretmen hesabının gururlu sahibisiniz.",
    "turtle": "🐢",
    "unsaved_class_changes": "Kaydedilmemiş değişiklikler var, bu sayfadan ayrılmak istediğinize emin misiniz?"
  },
  "uk": {
    "CheckInternet": "Перевірте, чи працює ваше підключення до Інтернету.",
    "Connection_error": "Не вдалося підключитися до сервера.",
    "Empty_output": "Цей код працює, але нічого не друкує. Додайте команду друку до свого коду або використовуйте черепаху, щоб отримати вивід.",
    "Errors_found": "Ви зробили помилку! Не турбуйтеся, ми продовимо виконувати програму",
    "Execute_error": "Щось пішло не так.",
    "Other_error": "Ой! Можливо, ми трохи помилилися.",
    "Program_repair": "Це може бути правильний код, ви можете його виправити?",
    "Program_too_long": "Ваша програма виконується надто довго.",
    "ServerError": "Ви можете допомогти нам, надіславши електронного листа з рівнем та своєю програмою на адресу hello@hedy.org. А поки що спробуйте щось трохи інше. Дякуємо!",
    "Transpile_error": "Ми не можемо запустити вашу програму.",
    "Transpile_success": "Гарна робота!\nДивовижно!\nЧудова робота!\nЧудово!\nВи чудово впоралися!",
    "Transpile_warning": "Попередження!",
    "Unsaved_Changes": "Ви маєте незбережену програму. Ви хочете вийти, не зберігши її?",
    "adventures_completed": "Здійснено пригод: {number_of_adventures}",
    "adventures_restored": "Стандартні пригоди відновлено.",
    "adventures_tried": "Пригоди пробували",
    "copy_link_to_share": "Скопіюйте посилання, щоб поділитися",
    "customization_deleted": "Налаштування успішно видалено.",
    "dice": "🎲",
    "directly_available": "Безпосередньо відкрити",
    "disabled": "Вимкнено",
    "errors": "Помилки",
    "fortune": "🔮, ✨",
    "graph_title": "Помилки в кожній пригоді, завершеній на рівні {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Рівень",
    "multiple_keywords_warning": "Ви намагаєтеся використати ключове слово {orig_keyword}, але воно може мати кілька значень. Будь ласка, виберіть із цього списку той, який ви намагаєтеся використати, і скопіюйте, вставте його у свій код, включаючи фігурні дужки: {keyword_list}",
    "number_of_errors": "Кількість помилок: {number_of_errors}",
    "one_level_error": "Потрібно вибрати хоча б один рівень.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Вибрати все",
    "selected": "Вибране",
    "songs": "🎵,🎶",
    "successful_runs": "Успішні пробіжки: {successful_runs}",
    "teacher_welcome": "Ласкаво просимо до Hedy! Тепер ви щасливий власник облікового запису вчителя, який дозволяє вам створювати класи та запрошувати студентів.",
    "turtle": "🐢",
    "unsaved_class_changes": "Є незбережені зміни. Ви впевнені, що бажаєте залишити цю сторінку?"
  },
  "ur": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "uz": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "vi": {
    "CheckInternet": "Hãy xem kết nối Internet của bạn có hoạt động bình thường không.",
    "Connection_error": "Chúng tôi không thể kết nối tới server.",
    "Empty_output": "Chương trình này hoạt động nhưng không in ra gì cả. Thêm lệnh in vào chương trình hoặc sử dụng con rùa để có đầu ra nhé.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Đã xảy ra sự cố khi chạy chương trình.",
    "Other_error": "Rất tiếc! Có lẽ chúng tôi đã phạm một sai lầm nhỏ.",
    "Program_repair": "Đây có thể là chương trình chính xác, bạn có thể sửa nó không?",
    "Program_too_long": "Chương trình của bạn tốn quá nhiều thời gian để chạy.",
    "ServerError": "Bạn đã viết một chương trình mà chúng tôi không mong đợi. Nếu bạn muốn trợ giúp, hãy gửi email cho chúng tôi về cấp độ và chương trình của bạn tại hello@hedy.org. Trong thời gian chờ đợi, hãy thử làm gì đó khác biệt một chút và xem lại các ví dụ. Xin cảm ơn!",
    "Transpile_error": "Chúng tôi không thể chạy chương trình của bạn.",
    "Transpile_success": "Làm tốt lắm!\nRất tuyệt vời!\nTốt lắm!\nHay quá!\nBạn làm rất tốt!",
    "Transpile_warning": "Cảnh báo!",
    "Unsaved_Changes": "Bạn có một chương trình chưa được lưu. Bạn có muốn thoát mà không lưu nó không?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 🔨,📜",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  },
  "zh_Hans": {
    "CheckInternet": "检查您的互联网连接是否正常。",
    "Connection_error": "我们无法连接到服务器。",
    "Empty_output": "这段代码可以工作，但没有打印任何东西。在你的代码中添加一个打印命令，或者使用乌龟来获得输出。",
    "Errors_found": "你搞错了！不要着急，我们仍然运行该程序",
    "Execute_error": "在运行程序时出了点问题。",
    "Other_error": "糟糕！ 也许我们犯了一个小错误。",
    "Program_repair": "这可能是正确的代码，你能修复它吗？",
    "Program_too_long": "你的程序运行的时间太长了。",
    "ServerError": "你写了一个出乎我们意料的程序。如果您想提供帮助，请通过 hello@hedy.org 向我们发送一封电子邮件，其中包含级别和您的程序。与此同时，尝试一些不同的东西，再看看这些例子。谢谢！",
    "Transpile_error": "我们无法运行你的程序。",
    "Transpile_success": "好样的！\n了不起！\n做的很好！\n棒极了！\n做的真棒！",
    "Transpile_warning": "警告！",
    "Unsaved_Changes": "你有一个未保存的程序。你想不保存就离开吗？",
    "adventures_completed": "已完成的冒险：{number_of_adventures}",
    "adventures_restored": "默认的冒险已经恢复。",
    "adventures_tried": "尝试冒险",
    "copy_link_to_share": "复制链接以分享",
    "customization_deleted": "已成功删除自定义项。",
    "dice": "🎲",
    "directly_available": "直接打开",
    "disabled": "禁用的",
    "errors": "错误",
    "fortune": "🔮, ✨",
    "graph_title": "在 {level} 级别完成的每次冒险的错误数",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "级别",
    "multiple_keywords_warning": "您正在尝试使用关键字 {orig_keyword}，但该关键字可能有多种含义。 请从此列表中选择您要使用的一个，然后将其复制粘贴到您的代码中，包括大括号：{keyword_list}",
    "number_of_errors": "错误数量：{number_of_errors}",
    "one_level_error": "你需要至少选择一个级别。",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "全选",
    "selected": "选中的",
    "songs": "🎵,🎶",
    "successful_runs": "成功运行：{successful_runs}",
    "teacher_welcome": "欢迎来到海蒂！ 您现在是教师帐户的自豪拥有者，该帐户允许您创建课程并邀请学生。",
    "turtle": "🐢",
    "unsaved_class_changes": "有未保存的更改，您确定要离开此页面吗？"
  },
  "zh_Hant": {
    "CheckInternet": "Check whether your Internet connection is working.",
    "Connection_error": "We couldn't reach the server.",
    "Empty_output": "This code works but does not print anything. Add a print command to your code or use the turtle to get output.",
    "Errors_found": "You made a mistake! Don't worry, we still ran the program",
    "Execute_error": "Something went wrong.",
    "Other_error": "Oops! Maybe we made a little mistake.",
    "Program_repair": "This could be the correct code, can you fix it?",
    "Program_too_long": "Your program takes too long to run.",
    "ServerError": "You can help us out by sending an email with the level and your program to hello@hedy.org. In the meantime, try something a little different. Thanks!",
    "Transpile_error": "We can't run your program.",
    "Transpile_success": "Good job!\nAmazing!\nWell done!\nExcellent!\nYou did great!",
    "Transpile_warning": "Warning!",
    "Unsaved_Changes": "You have an unsaved program. Do you want to leave without saving it?",
    "adventures_completed": "Adventures completed: {number_of_adventures}",
    "adventures_restored": "The default adventures have been restored.",
    "adventures_tried": "Adventures tried",
    "copy_link_to_share": "Copy link to share",
    "customization_deleted": "Customizations successfully deleted.",
    "dice": "🎲",
    "directly_available": "Directly open",
    "disabled": "Disabled",
    "errors": "Errors",
    "fortune": "🔮, ✨",
    "graph_title": "Errors per adventure completed on level {level}",
    "haunted": "🦇, 👻, 🎃",
    "level_title": "Level",
    "multiple_keywords_warning": "You are trying to use the keyword {orig_keyword}, but this keyword might have several meanings. Please choose the one you're trying to use from this list and copy paste it in your code, curly braces included: {keyword_list}",
    "number_of_errors": "Number of errors: {number_of_errors}",
    "one_level_error": "You need to select at least one level.",
    "restaurant": "🍣, 🍕, 🍔",
    "rock": "✂️, 📜, 🗻",
    "select_all": "Select all",
    "selected": "Selected",
    "songs": "🎵,🎶",
    "successful_runs": "Successful runs: {successful_runs}",
    "teacher_welcome": "Welcome to Hedy! Your are now the proud owner of a teachers account which allows you to create classes and invite students.",
    "turtle": "🐢",
    "unsaved_class_changes": "There are unsaved changes, are you sure you want to leave this page?"
  }
};
