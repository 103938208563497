// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "2`QYQPOOOOQO'#Dd'#DdQYQPOOO!TQPO'#DjO!iQPO'#DbOOQO'#Dn'#DnO!wQPO'#DQO#VQPO'#DROOQO'#Do'#DoO#bQPO'#DSOOQO'#Dp'#DpO#mQPO'#DUOOQO'#Dq'#DqO#xQPO'#DVOOQO'#Dr'#DrO$TQPO'#DWOOQO'#DT'#DTOOQO'#Ds'#DsO$`QPO'#DXOOQO'#Dt'#DtO$tQPO'#DYOOQO'#Dv'#DvO$|QPO'#DZOOQO'#Dx'#DxO%UQPO'#D[OOQO'#D|'#D|O&UQPO'#DcOOQO'#Cs'#CsQ!OQPO'#DeQ&]QPOOOOQO-E7b-E7bOOQO'#Df'#DfO&dQPO,59`OOQO'#Cv'#CvO&rQPO,59`OOQO'#Dj'#DjOOQO-E7h-E7hOOQO-E7l-E7lO&}QPO'#DmOOQO'#Cw'#CwO(aQPO'#CwOOQO'#Dm'#DmO(hQPO,59mO(|QPO,59nOOQO-E7m-E7mOOQO,59n,59nO)eQPO,59pOOQO-E7n-E7nOOQO,59p,59pO)|QPO,59qOOQO-E7o-E7oOOQO,59q,59qO*eQPO,59rOOQO-E7p-E7pOOQO,59r,59rO*|QPO,59sOOQO-E7q-E7qOOQO,59s,59sO+eQPO,59tOOQO-E7r-E7rO+mQPO,59uOOQO-E7t-E7tOOQO-E7v-E7vO+uQPO'#D^OOQO'#D]'#D]O`QPO,59vO`QPO,59}OOQO'#Da'#DaOOQO-E7z-E7zOOQO,59},59}O,TQPO,5:POOQO-E7c-E7cOOQO-E7d-E7dO-RQPO'#DgO-pQPO1G/SO.QQPO'#CwOOQO'#Dg'#DgO.eQPO1G.zO.vQPO1G/SOOQO'#Dl'#DlO/XQPO1G/UOOQO'#Cy'#CyOOQO'#Cz'#CzO/gQPO,5:nO/gQPO,5:nOOQO'#Dh'#DhO/oQPO,59gOOQO-E7k-E7kOOQO'#Du'#DuO/}QPO1G/`OOQO'#Dw'#DwO0VQPO1G/aO0_QPO,59xO0pQPO,59xOOQO'#Dz'#DzO1OQPO,59yOOQO'#D{'#D{O1WQPO,59zO`QPO1G/bO1`QPO1G/bOOQO1G/i1G/iOOQO'#C}'#C}O1kQPO'#DkO1sQPO7+$nOOQO-E7e-E7eOOQO-E7j-E7jO2[QPO7+$pOOQO1G0Y1G0YO2pQPO1G0YOOQO-E7f-E7fOOQO'#Di'#DiO3}QPO1G/ROOQO1G/R1G/ROOQO-E7s-E7sOOQO7+$z7+$zOOQO-E7u-E7uOOQO7+${7+${O5ZQPO1G/dOOQO'#Dy'#DyO5kQPO1G/dOOQO1G/d1G/dOOQO-E7x-E7xOOQO1G/e1G/eOOQO-E7y-E7yOOQO1G/f1G/fO5rQPO7+$|OOQO7+$|7+$|O5}QPO,5:VOOQO,5:V,5:VOOQO-E7i-E7iOOQO-E7g-E7gOOQO-E7w-E7wOOQO<<Hh<<Hh",
  stateData: "6l~OeOS!sOS~OSgOTiOVVOWYOX[OY^OZaO[WO^cO`eObTOiRO!tPO~O]oO!uqOi!^X!q!^X!t!^XT!^X~OisO!q!UX!t!UXT!UX~ObTO!qtX!ttXTtX~OixOlwOsyO~O[WOixOlwO~OWYOixOlwO~OX[OixOlwO~OY^OixOlwO~OZaOixOlwO!q{X!t{XT{X~O^cOisO~O`eOisO~OSgOi!aOl!aO~OTiOVVOWYOX[OY^OZaO[WO^cO`eObTOiRO~O!tPO~P%aOSgO~P%aOP!qO]oOi!mOl!lO~OP!qOi!mOl!lO~O!w!sO!x!sO!y!tO!z!tOi!aXl!aXs!aX!q!aX!t!aXT!aX~OQ!wOikXlkX!qkX!tkX!wkX!xkX!ykX!zkXTkX~OskX~P'oOixOlwOsyO!qua!tuaTua~O!w!sO!x!sO!y!tO!z!tO!qva!tvaTva~O!w!sO!x!sO!y!tO!z!tO!qxa!txaTxa~O!w!sO!x!sO!y!tO!z!tO!qya!tyaTya~O!w!sO!x!sO!y!tO!z!tO!qza!tzaTza~O!w!sO!x!sO!y!tO!z!tO!q{a!t{aT{a~Oa!zOisO~O_!|OisO~O]oOc#QOd#SO!uqO~O!tPOS!XaT!XaV!XaW!XaX!XaY!XaZ!Xa[!Xa^!Xa`!Xab!Xai!Xa!q!Xa~O!w!sO!x!sO!y!tO!z!tOi!ZXl!ZX!q!ZX!t!ZXT!ZX~O!{#XO!|#XO!}#XO#O#XO~P'rOi!^X!{!^X!|!^X!}!^X#O!^X~P'oOixOlwO!qhi!thiThi~OisO!{#XO!|#XO!}#XO#O#XO~OP!qOixOlwOsyO~OiwOlwO~OQ!wOR#bOi#dOl#dO~Oa!zOi#fO~O_!|Oi#hO~OU#jO]oOiwOlwOs#lO~OU#jOiwOlwOs#lO~Oc#QOi#nO~Od#SOi#pO~OTiO!q!Oi!t!Oi~OisOl#tO~O!{#XO!|#XO!}#XO#O#XO!qpq!tpqTpq~OixOlwOsyO!qrq!trqTrq~O!w!sO!x!sO!y!tO!z!tOi!vil!vis!vi!q!vi!t!viV!viW!viX!viY!viZ!vi[!vi^!vi`!vib!viT!vi~OR#bOioiloisoi!qoi!toiToi~OV!QiW!QiX!QiY!QiZ!Qi[!Qi^!Qi`!Qib!Qii!Qi!t!Qi~O!w!sO!x!sO!y!tO!z!tO~P4fOU#jO~P4fOTiO!q!Oq!t!Oq~OisO!q!_a!t!_a!{!_a!|!_a!}!_a#O!_aT!_a~Oli~",
  goto: ",T!wPPPPPPPPPPPPPPPPPPPPPPP!x!}P#Y#`P$U$a$l!}%W!}P!}!}!}!}%]%]%]!}!}!}%f%k%n%n%n%q!}%}&Y&l&s&}'U']'c(Q(X(`(j(v)S)`)l)x*U*b*h*t*z+S+Z+a+gPPPPP+qVlOQmUkOQmX!ej!c!d#UQrRR#P!aWvVz!r#^Q{XQ!OZQ!R]Q!U_Q!XbU!kpr!oQ#_!uQ#`!vT#i#O#Pc!uv{!O!R!U!X!k#`#ic!vv{!O!R!U!X!k#`#iWyVz!r#^Q}XQ!QZQ!T]Q!W_Q!ZbV!npr!oV#Y!l!p#Z_`OQjm!c!d#UVkOQmR!chR!bhQ!gjQ#V!cQ#W!dR#q#UUkOQmQ#r#VR#x#qQQOWnQ!d!h#UQ!djQ!hlR#U!cSmOQR!imQpRS!jp#OR#O!aS!oprR#[!oS!xx!mR#a!xQ#c!xR#v#c^SOQjm!c!d#UYtS![!^!p#sQ![dQ!^fS!pprR#s#YS#Z!l!pR#u#ZS!rprR#]!rQzVS!yz#^R#^!r^UOQjm!c!d#URuU^XOQjm!c!d#UR|X^ZOQjm!c!d#UR!PZ^]OQjm!c!d#UR!S]^_OQjm!c!d#UR!V_^bOQjm!c!d#UR!Yb^dOQjm!c!d#UR!]dQ!{![R#e!{^fOQjm!c!d#UR!_fQ!}!^R#g!}UhOQmR!`hS#k#O#PR#w#kQ#R!aR#m#RQ#T!aR#o#TYjOQm#V#qR!fjqwVXZ]_bprz!o!r!u!v#O#P#^",
  nodeNames: "⚠ ask at random if else pressed print forward turn color sleep play is add from remove to_list clear in not_in Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand ErrorInvalid Else",
  maxTerm: 92,
  nodeProps: [
    ["group", 39,"turtle"]
  ],
  skippedNodes: [0,21],
  repeatNodeCount: 25,
  tokenData: "1l~R!nYZ&Ppq&Uqr&Zrs*hst+]tu&Zuv&Zvw&Zwx+txy&Zyz&Zz{,d{|,i|},n}!O,s!O!P&Z!P!Q,x!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!_!`1W!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBnBo1]BoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FV&FW1b&FW;'S&Z;'S;=`*b<%l?Hb&Z?Hb?Hc1g?HcO&Z~&UO!t~~&ZO!s~~&`!`i~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R&Z!R!S&Z!S!T&Z!T!U&Z!U!V&Z!V!W&Z!W!X&Z!X!Y&Z!Y!Z&Z!Z![&Z![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg&ZDgDh&ZDhDi&ZDiDj&ZDjDk&ZDkDl&ZDlDm&ZDmDn&ZDnDo&ZDoDp&ZDvGl&ZGlGm&ZGmGn&ZGnGo&ZGoGp&ZGpGq&ZGqGr&ZGrGs&ZGsGt&ZGtGu&ZGuGv&ZGv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~*eP;=`<%l&Z~*kVOY*hZr*hrs+Qs#O*h#P;'S*h;'S;=`+V<%lO*h~+VOs~~+YP;=`<%l*h~+bSe~OY+]Z;'S+];'S;=`+n<%lO+]~+qP;=`<%l+]~+wVOY+tZw+twx+Qx#O+t#P;'S+t;'S;=`,^<%lO+t~,aP;=`<%l+t~,iO!w~~,nO!y~~,sO!{~~,xO!z~~,}O!x~~-U!`l~i~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~1]O!u~~1bO!|~~1gO#O~~1lO!}~",
  tokenizers: [0],
  topRules: {"Program":[0,22]},
  dynamicPrecedences: {"52":-10},
  specialized: [{term: 25, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 25, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 991
})
